define("@woody-lite/addon-crm-campaigns/components/notification-card", ["exports", "@ember/component", "@ember/template-factory", "@woody-lite/addon-crm-campaigns/components/crm-campaign", "@ember/object"], function (_exports, _component, _templateFactory, _crmCampaign, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @visible}}
  	<Cards::CardOffer
  		data-id={{concat "id_" @model.sourceName @model.sourcePriority}}
  		@align="left"
  		data-theme="secondary"
  		@image={{this.image}}
  		@title={{this.data.title}}
  		@text={{this.data.description}}
  		{{did-insert this.onDidInsert}}
  		as |card|
  	>
  		<card.button
  			data-id="bubbleButton"
  			@type="positive"
  			@text={{this.claim}}
  			@onClick={{this.sendOk}}
  		/>
  	</Cards::CardOffer>
  {{/if}}
  */
  {
    "id": "xIo2ZXBm",
    "block": "[[[41,[30,1],[[[1,\"\\t\"],[8,[39,1],[[16,\"data-id\",[28,[37,2],[\"id_\",[30,2,[\"sourceName\"]],[30,2,[\"sourcePriority\"]]],null]],[24,\"data-theme\",\"secondary\"],[4,[38,3],[[30,0,[\"onDidInsert\"]]],null]],[[\"@align\",\"@image\",\"@title\",\"@text\"],[\"left\",[30,0,[\"image\"]],[30,0,[\"data\",\"title\"]],[30,0,[\"data\",\"description\"]]]],[[\"default\"],[[[[1,\"\\n\\t\\t\"],[8,[30,3,[\"button\"]],[[24,\"data-id\",\"bubbleButton\"]],[[\"@type\",\"@text\",\"@onClick\"],[\"positive\",[30,0,[\"claim\"]],[30,0,[\"sendOk\"]]]],null],[1,\"\\n\\t\"]],[3]]]]],[1,\"\\n\"]],[]],null]],[\"@visible\",\"@model\",\"card\"],false,[\"if\",\"cards/card-offer\",\"concat\",\"did-insert\"]]",
    "moduleName": "@woody-lite/addon-crm-campaigns/components/notification-card.hbs",
    "isStrictMode": false
  });
  let NotificationCardComponent = (_class = class NotificationCardComponent extends _crmCampaign.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "endIcon", 'icon-add');
    }
    get data() {
      return this.model;
    }
    get model() {
      return this.args.model;
    }
    get claim() {
      return (0, _object.get)(this.data, 'claim');
    }
    get processStartedActions() {
      return (0, _object.get)(this.data, 'actions').filter(a => (0, _object.get)(a, 'trigger') === 'processStarted');
    }
    async initNavigation(targetAction) {
      this.doActions('processStartedActions');
      await this.transitionTo(targetAction);
    }
    _getActionUrl(crmAction) {
      const url = crmAction.url;
      return {
        url,
        validUrls: []
      };
    }
    transitionTo(crmAction) {
      const urls = this._getActionUrl(crmAction);
      const {
        url
      } = urls;
      const data = crmAction.inAppScreenData || {};
      this.args.transitionTo(url, {
        queryParams: data
      });
    }
    sendOk() {
      this.doActions('okActions');
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "sendOk", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "sendOk"), _class.prototype)), _class);
  _exports.default = NotificationCardComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, NotificationCardComponent);
});