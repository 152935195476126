define("@woody-lite/addon-conversations/repositories/messages", ["exports", "ember-object-data-model/repositories/repository", "@ember/service", "@ember/object", "@ember/application"], function (_exports, _repository, _service, _object, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const DEFAULT_MANAGER = {
    id: 'CC'
  };
  const MANAGER = {
    id: 'G'
  };
  let MessagesRepository = (_class = class MessagesRepository extends _repository.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "enax", _descriptor, this);
      _initializerDefineProperty(this, "provider", _descriptor2, this);
    }
    _getManager(manager) {
      if (manager.userCode) {
        return {
          name: manager.name,
          lastName: manager.lastName,
          mothersLastName: manager.mothersLastName,
          type: MANAGER
        };
      }
      return {
        name: 'PHILIP1',
        lastName: 'PHILIP1',
        type: DEFAULT_MANAGER
      };
    }
    async createMessage(threadId, message, attachments) {
      const customer = await this.provider.pull('customer', 'getContextualData');
      const payload = {
        content: message,
        sender: {
          name: customer.name,
          lastName: customer.lastName,
          mothersLastName: customer.mothersLastName,
          type: {
            id: 'C',
            name: 'CLIENTE'
          }
        },
        receiver: this._getManager((0, _object.get)(customer, 'manager')),
        isDraft: false,
        attachments
      };
      const data = await this.enax.POST('messages', payload, {
        threadId
      });
      const normalizer = (0, _application.getOwner)(this).lookup('normalizer:thread-message');
      const normalizeMessage = normalizer.normalize(data);
      return this.createEntry('thread-message', normalizeMessage.properties.id, normalizeMessage.properties);
    }
    markAsRead(message) {
      return this.enax.PUT('messages', {
        readed: true
      }, {
        threadId: (0, _object.get)(message, 'messageThreadId'),
        messageId: message.id
      });
    }
    async getDetail(model) {
      const payload = {
        threadId: (0, _object.get)(model, 'messageThreadId'),
        messageId: (0, _object.get)(model, 'id')
      };
      const data = await this.enax.GET('messages', payload);
      const normalizer = (0, _application.getOwner)(this).lookup('normalizer:thread-message-detail');
      const normalizeDetail = normalizer.normalize(data);
      const modelizeAttachments = normalizeDetail.properties.attachments.map(attachment => this.createEntry('thread-message-attachment', attachment.id, attachment));
      normalizeDetail.properties.attachments = modelizeAttachments;
      return this.createEntry('thread-message-detail', normalizeDetail.properties.id, normalizeDetail.properties);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "enax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = MessagesRepository;
});