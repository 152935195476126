define("@woody-lite/addon-feedback/services/opinator-configuration", ["exports", "@ember/service", "@woody-lite/core-session/decorators/session"], function (_exports, _service, _session) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let OpinatorConfigurationService = (_dec = (0, _session.default)('client'), (_class = class OpinatorConfigurationService extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "enax", _descriptor, this);
      _initializerDefineProperty(this, "currentSession", _descriptor2, this);
    }
    fetch(currentRoute, feedbackFormId, feedbackType) {
      try {
        const route = this.getCleanRoute(currentRoute);
        return this.fetchFeedback(route, feedbackFormId, feedbackType);
      } catch {
        return {};
      }
    }
    async fetchFeedback(route, feedbackFormId, feedbackType) {
      try {
        var _this$currentSession, _response$link;
        const customerId = (_this$currentSession = this.currentSession) === null || _this$currentSession === void 0 ? void 0 : _this$currentSession.id;
        const resourceEnax = customerId ? 'feedback-client' : 'feedback';
        const payload = {
          params: {
            customerId,
            useCustomerManager: false
          }
        };
        const data = await this.enax.GET("".concat(resourceEnax), payload);
        const response = this.getLinkRoute(data === null || data === void 0 ? void 0 : data.items, route, feedbackFormId, feedbackType);
        return response === null || response === void 0 ? void 0 : (_response$link = response.link) === null || _response$link === void 0 ? void 0 : _response$link.href;
      } catch {
        // Noop
      }
      return null;
    }
    getCleanRoute(currentRoute) {
      const splits = currentRoute.split('/');
      if (splits[1]) {
        return splits[1].substr(7);
      }
      return splits[0];
    }
    getLinkRoute(items, route, feedbackFormId, feedbackType) {
      var _form$links;
      const routeForm = items.find(item => item.id === route);
      const form = routeForm === null || routeForm === void 0 ? void 0 : routeForm.forms.find(f => f.id === feedbackFormId);
      return form === null || form === void 0 ? void 0 : (_form$links = form.links) === null || _form$links === void 0 ? void 0 : _form$links.find(l => {
        var _l$type;
        return (l === null || l === void 0 ? void 0 : (_l$type = l.type) === null || _l$type === void 0 ? void 0 : _l$type.id) === feedbackType;
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "enax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentSession", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = OpinatorConfigurationService;
});