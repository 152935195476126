define("@woody-lite/core-session/components/modal-face-recognition", ["exports", "@woody-lite/core-foundation/components/modal", "@ember/service", "@ember/object", "@glimmer/tracking", "@woody-lite/core-session/resources/client-enax", "@woody-lite/core-session/templates/modal-face-recognition"], function (_exports, _modal, _service, _object, _tracking, _clientEnax, _modalFaceRecognition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const ALLOW = 'camera; microphone; geolocation; accelerometer; gyroscope;';
  let ModalFaceRecognitionComponent = (_class = class ModalFaceRecognitionComponent extends _modal.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "layout", _modalFaceRecognition.default);
      _defineProperty(this, "allowedDomains", ['https://xpressid-work.eu.veri-das.com']);
      _initializerDefineProperty(this, "veil", _descriptor, this);
      _initializerDefineProperty(this, "slinky", _descriptor2, this);
      _initializerDefineProperty(this, "provider", _descriptor3, this);
      _initializerDefineProperty(this, "modal", _descriptor4, this);
      _initializerDefineProperty(this, "urlFrame", _descriptor5, this);
      _initializerDefineProperty(this, "permissionsRequested", _descriptor6, this);
      _initializerDefineProperty(this, "takingSelfie", _descriptor7, this);
      _initializerDefineProperty(this, "selfieStatus", _descriptor8, this);
      _defineProperty(this, "resource", null);
      _defineProperty(this, "allow", ALLOW);
      this.veil.remove();
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      this.resource = this.model.options.resource;
      const authenticationresponse = JSON.parse(atob(this.resource.authenticationresponse));
      this.urlFrame = authenticationresponse.urlFrame;
      this.permissionsRequested = false;
      this.veil.remove();
    }
    async requestPermissions() {
      try {
        const device = await this.provider.pull('device', 'getDevice');
        const isAndroid = device.isAndroid;
        if (isAndroid) {
          const {
            permission: cameraPermission
          } = await this.slinky.send('Device.requestPermission', {
            permission: 'camera'
          });
          const {
            permission: microphonePermission
          } = await this.slinky.send('Device.requestPermission', {
            permission: 'microphone'
          });
          if (!cameraPermission || !microphonePermission) {
            await this.modal.open('face-recognition-error', {
              errorType: 'permissions'
            });
            this.reject();
          }
        }
      } catch {
        this.permissionsRequested = false;
      } finally {
        this.veil.remove();
      }
      this.permissionsRequested = true;
      this.takingSelfie = true;
    }
    async processError() {
      await this.modal.open('face-recognition-error', {
        errorType: 'generic'
      });
      this.reject();
    }
    async processCompleted() {
      this.takingSelfie = false;
      this.selfieStatus = 'success';
    }
    retryWithOtp() {
      this.veil.add();
      this.close({
        selectSignatureType: _clientEnax.SIGNATURE_TYPES.FACE_RECOGNITION
      });
    }
    async processCancelled() {
      await this.modal.open('face-recognition-error', {
        errorType: 'cancelled'
      });
      this.reject();
    }
    closeModal() {
      this.reject();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "veil", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "slinky", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "urlFrame", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "permissionsRequested", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "takingSelfie", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "selfieStatus", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'pending';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "requestPermissions", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "requestPermissions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "processError", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "processError"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "processCompleted", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "processCompleted"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "retryWithOtp", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "retryWithOtp"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "processCancelled", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "processCancelled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype)), _class);
  _exports.default = ModalFaceRecognitionComponent;
});