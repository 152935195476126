define("@woody-lite/core-session/instance-initializers/html-session-id", ["exports", "ember-utils/utils/hash/guid"], function (_exports, _guid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  async function initialize() {
    var _window$HtmlSessionId;
    window.HtmlSessionId = (_window$HtmlSessionId = window.HtmlSessionId) !== null && _window$HtmlSessionId !== void 0 ? _window$HtmlSessionId : {
      _id: (0, _guid.default)(),
      getId() {
        return this._id;
      },
      refresh() {
        return this._generateId();
      },
      async _generateId() {
        this._id = (0, _guid.default)();
      }
    };
  }
  var _default = {
    name: 'html-session-id',
    initialize
  };
  _exports.default = _default;
});