define("@woody-lite/core-session/models/session/base", ["exports", "ember-object-data-model/models/base", "@ember/application", "@ember/object", "@ember/service"], function (_exports, _base, _application, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let SessionModel = (_class = class SessionModel extends _base.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "provider", _descriptor, this);
      _defineProperty(this, "_timers", []);
      this._createTimers();
      this._runTimers();
      this.sync();
    }
    sync() {
      this.provider.push('session', 'storeSession', this);
      return super.sync(...arguments);
    }
    getTimer(name) {
      return this._timers.find(_ref => {
        let {
          id
        } = _ref;
        return id === name;
      });
    }
    _createTimers() {}
    _createTimer(options) {
      const timer = (0, _application.getOwner)(this).factoryFor('object:timer').create(options);
      this._timers.push(timer);
      return timer;
    }
    _runTimers() {
      this._timers.forEach(timer => timer.run());
    }
    _clearTimers() {
      this._timers.forEach(timer => timer.stop());
    }
    keepAlive() {}
    async logout() {
      this._clearTimers();
      const result = await this.provider.push('session', 'logout', this);
      try {
        var _getOwner$lookup;
        const router = (_getOwner$lookup = (0, _application.getOwner)(this).lookup('service:router')) === null || _getOwner$lookup === void 0 ? void 0 : _getOwner$lookup._router;
        router.send('unauthenticated');
      } catch {
        //
      }
      return result;
    }
    toJSON() {
      return this.content;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "logout", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "logout"), _class.prototype)), _class);
  _exports.default = SessionModel;
});