define("@woody-lite/core-traceability/instance-initializers/tealium-route-tracking", ["exports", "@ember/application/instance", "@ember/engine/instance", "ember-utils/utils/primitive/merge", "@woody-lite/core-traceability/utils/get-page-levels", "@woody-lite/core-traceability/utils/page-name", "@woody-lite/core-traceability/utils/tealium-context", "@woody-lite/core-traceability/events/view", "@woody-lite/core-traceability/utils/is-adapter-enabled", "@ember/object"], function (_exports, _instance, _instance2, _merge, _getPageLevels, _pageName, _tealiumContext, _view, _isAdapterEnabled, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  const instances = {};
  function lookupRoute(routeName) {
    let owner = instances['host-app'];
    const engineName = Object.keys(instances).find(key => key !== 'host-app' && routeName.startsWith(key));
    if (engineName) {
      owner = instances[engineName];
      routeName = routeName.slice(engineName.length + 1) || 'application';
    }
    return owner.lookup("route:".concat(routeName), {
      instantiate: false
    });
  }
  async function getRouteTrackingData(transition) {
    let node = transition.to;
    let route;
    let routeHasTrackingDataMethod = false;
    do {
      var _route;
      route = lookupRoute(node.name);
      node = node.parent;
      routeHasTrackingDataMethod = typeof ((_route = route) === null || _route === void 0 ? void 0 : _route.trackingData) === 'function';
    } while (node && !routeHasTrackingDataMethod);
    if (routeHasTrackingDataMethod) {
      return await route.trackingData();
    }
    return null;
  }
  function getPageName(metrics, name) {
    const area = (0, _tealiumContext.getContext)(metrics, 'session.page.pageInfo.area');
    return (0, _pageName.default)(area, name);
  }
  function completeRouteTrackingData(metrics, baseData, fullRouteName) {
    const levels = (0, _getPageLevels.default)(fullRouteName);
    const pageInfo = {
      pageName: getPageName(metrics, fullRouteName)
    };
    const obj = {
      page: {
        pageInfo,
        pageActivity: {
          loginType: 'CREDENTIALS'
        }
      }
    };
    levels.slice(0, _getPageLevels.MAX_PAGE_LEVELS).forEach((level, index) => {
      pageInfo["level".concat(index + 1)] = level;
    });
    return (0, _merge.default)(obj, baseData);
  }
  async function getLoginType(provider, trackingData) {
    const customer = await provider.pull('customer', 'getContextualData');
    trackingData.page.pageActivity.loginType = (0, _object.get)(customer, 'status.accessType');
  }
  async function setupRouteListener(metrics, router, scheduler, provider) {
    const callback = scheduler.scheduleOnce.bind(scheduler, this, async transition => {
      const routeTrackingData = await getRouteTrackingData(transition);
      const trackingData = completeRouteTrackingData(metrics, routeTrackingData, transition.to.name);
      const device = await provider.pull('device', 'getDevice');
      if ((0, _object.get)(device, 'isNative')) {
        const sessionData = await provider.pull('slinky', 'Session.getData');
        if ((0, _object.get)(sessionData, 'scope') === 'PRIVATE') {
          try {
            const timeoutPromise = new Promise((_, reject) => {
              setTimeout(() => {
                reject(new Error('timeout'));
              }, 1000);
            });
            const promise = getLoginType(provider, trackingData);
            await Promise.race([promise, timeoutPromise]);
          } catch {
            trackingData.page.pageActivity.loginType = 'CREDENTIALS';
          }
        }
      }
      (0, _tealiumContext.setContext)(metrics, 'currentRoute');
      metrics.trackEvent('tealium', new _view.default(trackingData));

      // `context.currentRoute` will only work if you share the `metrics` service between engine & app
      (0, _tealiumContext.setContext)(metrics, 'currentRoute', trackingData);
    });
    router.on('routeDidChange', transition => {
      if (!transition.isAborted) {
        callback(transition);
      }
    });
  }
  async function initialize(instance) {
    const config = instance.resolveRegistration('config:environment');
    if ((0, _isAdapterEnabled.default)(config)) {
      // This condition prevents initializing the adapter
      const metrics = instance.lookup('service:metrics');
      if (metrics) {
        if (instance instanceof _instance.default) {
          const router = instance.lookup('service:router');
          const scheduler = instance.lookup('service:scheduler');
          const provider = instance.lookup('service:provider');
          instances['host-app'] = instance;
          await setupRouteListener(metrics, router, scheduler, provider);
        } else if (instance instanceof _instance2.default && instance.mountPoint) {
          instances[instance.mountPoint] = instance;
        }
      }
    }
  }
  var _default = {
    name: 'tealium/route-tracking',
    initialize
  };
  _exports.default = _default;
});