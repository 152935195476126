define("@woody-lite/engine-feedback-visible/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _routes.default)(function () {
    this.route('index', {
      path: '/'
    }, function () {
      this.route('index', {
        path: '/rates'
      });
      this.route('opinions');
      this.route('filtered-opinions');
    });
  });
  _exports.default = _default;
});