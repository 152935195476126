define("@woody-lite/core-traceability/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.BASE_OBJECT = void 0;
  const BASE_OBJECT = {
    versionDL: '',
    pageInstanceID: '',
    page: {
      pageInfo: {
        pageName: '',
        pageIntent: '',
        pageSegment: '',
        sysEnv: '',
        version: '',
        channel: '',
        language: '',
        geoRegion: '',
        level1: '',
        level2: '',
        level3: '',
        level4: '',
        level5: '',
        level6: '',
        level7: '',
        level8: '',
        level9: '',
        level10: '',
        area: '',
        server: '',
        errorPage: '',
        businessUnit: '',
        siteAppName: '',
        projectName: ''
      },
      pageActivity: {
        loginType: '',
        search: {
          onSiteSearchResults: '',
          onSiteSearchTerm: '',
          onSiteSearchEnterTerm: ''
        },
        link: {
          name: '',
          url: '',
          ext: '',
          aux1: '',
          aux2: '',
          aux3: ''
        },
        video: {
          attributes: [{
            nameOfVideoDisplayed: '',
            duration: '',
            id: '',
            player: '',
            quality: '',
            url: ''
          }],
          event: {
            eventInfo: {
              eventName: '',
              siteActionName: ''
            }
          }
        },
        audio: {
          attributes: [{
            nameOfPodcastDisplayed: '',
            duration: '',
            id: '',
            player: '',
            quality: '',
            url: ''
          }],
          event: {
            eventInfo: {
              eventName: '',
              siteActionName: ''
            }
          }
        }
      }
    },
    internalCampaign: {
      attributes: [{
        location: '',
        campaignFormat: '',
        collectiveCode: '',
        campaignName: '',
        product: '',
        productCode: '',
        quantity: ''
      }],
      event: {
        eventInfo: {
          eventName: '',
          siteActionName: ''
        }
      }
    },
    user: {
      device: {
        userAgent: '',
        mobile: '',
        root: '',
        consent: {
          version: 5,
          analitica: true,
          personalizacion: true,
          publicidad: true
        }
      },
      userState: '',
      profileID: [{
        aap: '',
        externalId: ''
      }],
      prospectID: '',
      segment: [{
        global: '',
        profile: ''
      }],
      gender: '',
      country: '',
      state: '',
      age: '',
      civilStatus: '',
      educationLevel: '',
      jobType: '',
      userID: ''
    },
    application: {
      transactionID: '',
      application: {
        type: '',
        name: ''
      },
      vehicle: [{
        type: '',
        status: '',
        year: '',
        amount: '',
        dealerName: ''
      }],
      fulfillmentModel: '',
      typology: '',
      programTypeHired: '',
      offer: '',
      operationNumber: '',
      process: '',
      step: '',
      interactionLevel: '',
      isQualifiedVisits: '',
      state: '',
      errorType: '',
      earnings: '',
      expenses: '',
      customFields: '',
      globalApplication: '',
      cta: ''
    },
    products: {
      attributes: [{
        primaryCategory: '',
        productSubtype: '',
        productName: '',
        productCode: '',
        quantity: '',
        amount: '',
        paymentAmount: '',
        numberOfPayments: '',
        paymentDate: '',
        paymentType: '',
        serviceCharge: '',
        currency: '',
        numberOfHolders: '',
        interestRate: {
          tin: '',
          tae: '',
          rate: ''
        },
        term: '',
        group: '',
        state: ''
      }],
      productPortfolio: [{
        productName: '',
        balance: '',
        currency: '',
        productCode: ''
      }]
    },
    optimization: {
      attributes: [{
        idOptimization: '',
        experience: '',
        place: '',
        type: '',
        executor: '',
        audience: ''
      }],
      event: {
        eventName: '',
        optimizationEvent: ''
      }
    },
    news: [{
      id: '',
      originalTitle: '',
      title: '',
      type: '',
      originalPublishDate: '',
      modifiedDate: '',
      country: '',
      category: '',
      signature: '',
      author: '',
      countryAuthor: '',
      relatedId: '',
      relatedOriginalTitle: '',
      relatedPosition: '',
      profile: {
        lifeCycle: '',
        value: '',
        behaviour: ''
      },
      product: '',
      channel: ''
    }]
  };
  _exports.BASE_OBJECT = BASE_OBJECT;
});