define("@woody-lite/core-traceability/utils/user-segment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SEGMENTS = _exports.PROFILE_SEGMENT_PREFIX = void 0;
  _exports.default = userSegment;
  const SEGMENTS = {
    // particulares
    HOME: {
      CODE: '724000',
      VALUES: ['21', '22', '23', '24', '25', '32', '51', '52', '53', '54', '55', '56', '61', '62', '80', '81', '82', '83', '84', '85', '86', '87', '88', '89']
    },
    // banca privada
    PRIVATE_BANKING: {
      CODE: '724010',
      VALUES: ['11', '12', '41', '42']
    },
    // banca personal
    PERSONAL_BANKING: {
      CODE: '724020',
      VALUES: ['13', '14', '15', '43']
    },
    // empresas
    BUSINESS: {
      CODE: '724030',
      VALUES: ['31', '33', '34', '35', '36', '37', '38']
    },
    // instituciones
    INSTITUTIONS: {
      CODE: '724040',
      VALUES: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '71', '72', '73', '74', '75', '76']
    },
    // otros
    OTHERS: {
      CODE: '724050',
      VALUES: ['0', '63']
    }
  };
  _exports.SEGMENTS = SEGMENTS;
  const PROFILE_SEGMENT_PREFIX = '7240';
  _exports.PROFILE_SEGMENT_PREFIX = PROFILE_SEGMENT_PREFIX;
  const SEGMENT_LIST = Object.keys(SEGMENTS).map(key => SEGMENTS[key]);
  function userSegment(globalSegmentValue) {
    const globalSegment = SEGMENT_LIST.find(s => s.VALUES.includes(globalSegmentValue));
    return [{
      global: globalSegment ? globalSegment.CODE : undefined,
      profile: globalSegmentValue ? "".concat(PROFILE_SEGMENT_PREFIX).concat(globalSegmentValue) : undefined
    }];
  }
});