define("@woody-lite/addon-utils/currency-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AVAILABLE_CURRENCY = void 0;
  const AVAILABLE_CURRENCY = {
    localCurrencies: [{
      id: 'EUR',
      default: 'true'
    }],
    defaultDestinationCurrencies: [{
      id: 'USD',
      default: 'true'
    }],
    availableCurrencies: [{
      id: 'AED',
      name: 'label.AED',
      symbol: 'د.إ',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_AE.png',
      countryList: [{
        id: 'AE',
        name: 'Emiratos Árabes Unidos'
      }]
    }, {
      id: 'AFN',
      name: 'label.AFN',
      symbol: '؋',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_AF.png',
      countryList: [{
        id: 'AF',
        name: 'Afganistán'
      }]
    }, {
      id: 'ALL',
      name: 'label.ALL',
      symbol: 'L',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_AL.png',
      countryList: [{
        id: 'AL',
        name: 'Albania'
      }]
    }, {
      id: 'AMD',
      name: 'label.AMD',
      symbol: '֏',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_AM.png',
      countryList: [{
        id: 'AM',
        name: 'Armenia'
      }]
    }, {
      id: 'ANG',
      name: 'label.ANG',
      symbol: 'NAƒ',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_earth.png',
      countryList: [{
        id: 'CW',
        name: 'Curaçao'
      }, {
        id: 'SX',
        name: 'Sint Maarten'
      }]
    }, {
      id: 'AOA',
      name: 'label.AOA',
      symbol: 'Kz',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_AO.png',
      countryList: [{
        id: 'AO',
        name: 'Angola'
      }]
    }, {
      id: 'ARS',
      name: 'label.ARS',
      symbol: '$',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_AR.png',
      countryList: [{
        id: 'AR',
        name: 'Argentina'
      }]
    }, {
      id: 'AUD',
      name: 'label.AUD',
      symbol: 'A$',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_AU.png',
      countryList: [{
        id: 'AU',
        name: 'Australia'
      }, {
        id: 'CC',
        name: 'Islas Cocos'
      }, {
        id: 'CX',
        name: 'Isla de Navidad'
      }, {
        id: 'HM',
        name: 'Islas Heard y McDonald'
      }, {
        id: 'KI',
        name: 'Kiribati'
      }, {
        id: 'NF',
        name: 'Isla Norfolk'
      }, {
        id: 'NR',
        name: 'Nauru'
      }, {
        id: 'TV',
        name: 'Tuvalu'
      }]
    }, {
      id: 'AWG',
      name: 'label.AWG',
      symbol: 'Afl.',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_AW.png',
      countryList: [{
        id: 'AW',
        name: 'Aruba'
      }]
    }, {
      id: 'AZN',
      name: 'label.AZN',
      symbol: '₼',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_AZ.png',
      countryList: [{
        id: 'AZ',
        name: 'Azerbaiyán'
      }]
    }, {
      id: 'BAM',
      name: 'label.BAM',
      symbol: 'KM',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_BA.png',
      countryList: [{
        id: 'BA',
        name: 'Bosnia y Herzegovina'
      }]
    }, {
      id: 'BBD',
      name: 'label.BBD',
      symbol: 'Bds$',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_BB.png',
      countryList: [{
        id: 'BB',
        name: 'Barbados'
      }]
    }, {
      id: 'BDT',
      name: 'label.BDT',
      symbol: '৳',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_BD.png',
      countryList: [{
        id: 'BD',
        name: 'Bangladés'
      }]
    }, {
      id: 'BGN',
      name: 'label.BGN',
      symbol: 'Лв',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_BG.png',
      countryList: [{
        id: 'BG',
        name: 'Bulgaria'
      }]
    }, {
      id: 'BHD',
      name: 'label.BHD',
      symbol: '.د.ب',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_BH.png',
      countryList: [{
        id: 'BH',
        name: 'Baréin'
      }]
    }, {
      id: 'BIF',
      name: 'label.BIF',
      symbol: 'FBu',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_BI.png',
      countryList: [{
        id: 'BI',
        name: 'Burundi'
      }]
    }, {
      id: 'BMD',
      name: 'label.BMD',
      symbol: 'BD$',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_BM.png',
      countryList: [{
        id: 'BM',
        name: 'Bermudas'
      }]
    }, {
      id: 'BND',
      name: 'label.BND',
      symbol: 'B$',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_BN.png',
      countryList: [{
        id: 'BN',
        name: 'Brunéi'
      }]
    }, {
      id: 'BOB',
      name: 'label.BOB',
      symbol: 'Bs',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_BO.png',
      countryList: [{
        id: 'BO',
        name: 'Bolivia'
      }]
    }, {
      id: 'BRL',
      name: 'label.BRL',
      symbol: 'R$',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_BR.png',
      countryList: [{
        id: 'BR',
        name: 'Brasil'
      }]
    }, {
      id: 'BSD',
      name: 'label.BSD',
      symbol: 'B$',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_BS.png',
      countryList: [{
        id: 'BS',
        name: 'Bahamas'
      }]
    }, {
      id: 'BTN',
      name: 'label.BTN',
      symbol: 'Nu.',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_BT.png',
      countryList: [{
        id: 'BT',
        name: 'Bután'
      }]
    }, {
      id: 'BWP',
      name: 'label.BWP',
      symbol: 'P',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_BW.png',
      countryList: [{
        id: 'BW',
        name: 'Botsuana'
      }]
    }, {
      id: 'BYN',
      name: 'label.BYN',
      symbol: 'Br',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_BY.png',
      countryList: [{
        id: 'BY',
        name: 'Bielorrusia'
      }]
    }, {
      id: 'BZD',
      name: 'label.BZD',
      symbol: 'Bz$',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_BZ.png',
      countryList: [{
        id: 'BZ',
        name: 'Belice'
      }]
    }, {
      id: 'CAD',
      name: 'label.CAD',
      symbol: 'C$',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_CA.png',
      countryList: [{
        id: 'CA',
        name: 'Canadá'
      }]
    }, {
      id: 'CDF',
      name: 'label.CDF',
      symbol: 'F',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_CD.png',
      countryList: [{
        id: 'CD',
        name: 'República Democrática del Congo'
      }]
    }, {
      id: 'CHF',
      name: 'label.CHF',
      symbol: 'Fr.',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_CH.png',
      countryList: [{
        id: 'CH',
        name: 'Suiza'
      }, {
        id: 'LI',
        name: 'Liechtenstein'
      }]
    }, {
      id: 'CLP',
      name: 'label.CLP',
      symbol: 'Ch$',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_CL.png',
      countryList: [{
        id: 'CL',
        name: 'Chile'
      }]
    }, {
      id: 'CNY',
      name: 'label.CNY',
      symbol: '¥',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_CN.png',
      countryList: [{
        id: 'CN',
        name: 'China'
      }]
    }, {
      id: 'COP',
      name: 'label.COP',
      symbol: '$',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_CO.png',
      countryList: [{
        id: 'CO',
        name: 'Colombia'
      }]
    }, {
      id: 'CRC',
      name: 'label.CRC',
      symbol: '₡',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_CR.png',
      countryList: [{
        id: 'CR',
        name: 'Costa Rica'
      }]
    }, {
      id: 'CUP',
      name: 'label.CUP',
      symbol: '$',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_CU.png',
      countryList: []
    }, {
      id: 'CVE',
      name: 'label.CVE',
      symbol: 'Esc.',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_CV.png',
      countryList: [{
        id: 'CV',
        name: 'Cabo Verde'
      }]
    }, {
      id: 'CZK',
      name: 'label.CZK',
      symbol: 'Kč',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_CZ.png',
      countryList: [{
        id: 'CZ',
        name: 'República Checa'
      }]
    }, {
      id: 'DJF',
      name: 'label.DJF',
      symbol: '₣',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_DJ.png',
      countryList: [{
        id: 'DJ',
        name: 'Yibuti'
      }]
    }, {
      id: 'DKK',
      name: 'label.DKK',
      symbol: 'kr.',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_DK.png',
      countryList: [{
        id: 'DK',
        name: 'Dinamarca'
      }, {
        id: 'FO',
        name: 'Islas Feroe'
      }, {
        id: 'GL',
        name: 'Groenlandia'
      }]
    }, {
      id: 'DOP',
      name: 'label.DOP',
      symbol: 'RD$',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_DO.png',
      countryList: [{
        id: 'DO',
        name: 'República Dominicana'
      }]
    }, {
      id: 'DZD',
      name: 'label.DZD',
      symbol: 'دج',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_DZ.png',
      countryList: [{
        id: 'DZ',
        name: 'Argelia'
      }]
    }, {
      id: 'EGP',
      name: 'label.EGP',
      symbol: 'LE',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_EG.png',
      countryList: [{
        id: 'EG',
        name: 'Egipto'
      }]
    }, {
      id: 'ETB',
      name: 'label.ETB',
      symbol: 'Br',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_ET.png',
      countryList: [{
        id: 'ET',
        name: 'Etiopía'
      }]
    }, {
      id: 'EUR',
      name: 'label.EUR',
      symbol: '€',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_comunidad_europea.png',
      countryList: [{
        id: 'AD',
        name: 'Andorra'
      }, {
        id: 'AT',
        name: 'Austria'
      }, {
        id: 'AX',
        name: 'Islas Åland'
      }, {
        id: 'BE',
        name: 'Bélgica'
      }, {
        id: 'BL',
        name: 'San Bartolomé'
      }, {
        id: 'CY',
        name: 'Chipre'
      }, {
        id: 'DE',
        name: 'Alemania'
      }, {
        id: 'EE',
        name: 'Estonia'
      }, {
        id: 'ES',
        name: 'España'
      }, {
        id: 'FI',
        name: 'Finlandia'
      }, {
        id: 'FR',
        name: 'Francia'
      }, {
        id: 'GF',
        name: 'Guayana Francesa'
      }, {
        id: 'GP',
        name: 'Guadalupe'
      }, {
        id: 'GR',
        name: 'Grecia'
      }, {
        id: 'IE',
        name: 'Irlanda'
      }, {
        id: 'IT',
        name: 'Italia'
      }, {
        id: 'LT',
        name: 'Lituania'
      }, {
        id: 'LU',
        name: 'Luxemburgo'
      }, {
        id: 'LV',
        name: 'Letonia'
      }, {
        id: 'MC',
        name: 'Mónaco'
      }, {
        id: 'ME',
        name: 'Montenegro'
      }, {
        id: 'MF',
        name: 'San Martín'
      }, {
        id: 'MQ',
        name: 'Martinica'
      }, {
        id: 'MT',
        name: 'Malta'
      }, {
        id: 'NL',
        name: 'Países Bajos'
      }, {
        id: 'PM',
        name: 'San Pedro y Miquelón'
      }, {
        id: 'PT',
        name: 'Portugal'
      }, {
        id: 'RE',
        name: 'Reunión'
      }, {
        id: 'SI',
        name: 'Eslovenia'
      }, {
        id: 'SK',
        name: 'Eslovaquia'
      }, {
        id: 'SM',
        name: 'San Marino'
      }, {
        id: 'TF',
        name: 'Tierras Australes y Antárticas Francesas'
      }, {
        id: 'VA',
        name: 'Ciudad del Vaticano'
      }, {
        id: 'YT',
        name: 'Mayotte'
      }]
    }, {
      id: 'FJD',
      name: 'label.FJD',
      symbol: 'FJ$',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_FJ.png',
      countryList: [{
        id: 'FJ',
        name: 'Fiyi'
      }]
    }, {
      id: 'FKP',
      name: 'label.FKP',
      symbol: 'FK£',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_FK.png',
      countryList: [{
        id: 'FK',
        name: 'Islas Malvinas'
      }]
    }, {
      id: 'GBP',
      name: 'label.GBP',
      symbol: '£',
      rightAlignedCurrency: false,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_GB.png',
      countryList: [{
        id: 'GB',
        name: 'Reino Unido'
      }, {
        id: 'GG',
        name: 'Guernsey'
      }, {
        id: 'IM',
        name: 'Isla de Man'
      }, {
        id: 'JE',
        name: 'Jersey'
      }]
    }, {
      id: 'GEL',
      name: 'label.GEL',
      symbol: '₾',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_GE.png',
      countryList: [{
        id: 'GE',
        name: 'Georgia'
      }]
    }, {
      id: 'GHS',
      name: 'label.GHS',
      symbol: 'GH₵',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_GH.png',
      countryList: [{
        id: 'GH',
        name: 'Ghana'
      }]
    }, {
      id: 'GIP',
      name: 'label.GIP',
      symbol: '£',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_GI.png',
      countryList: [{
        id: 'GI',
        name: 'Gibraltar'
      }]
    }, {
      id: 'GMD',
      name: 'label.GMD',
      symbol: 'D',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_GM.png',
      countryList: [{
        id: 'GM',
        name: 'Gambia'
      }]
    }, {
      id: 'GNF',
      name: 'label.GNF',
      symbol: 'FG',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_GN.png',
      countryList: [{
        id: 'GN',
        name: 'Guinea'
      }]
    }, {
      id: 'GTQ',
      name: 'label.GTQ',
      symbol: 'Q',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_GT.png',
      countryList: [{
        id: 'GT',
        name: 'Guatemala'
      }]
    }, {
      id: 'GYD',
      name: 'label.GYD',
      symbol: 'GY$',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_GY.png',
      countryList: [{
        id: 'GY',
        name: 'Guyana'
      }]
    }, {
      id: 'HKD',
      name: 'label.HKD',
      symbol: 'HK$',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_HK.png',
      countryList: [{
        id: 'HK',
        name: 'Hong Kong'
      }]
    }, {
      id: 'HNL',
      name: 'label.HNL',
      symbol: 'L',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_HN.png',
      countryList: [{
        id: 'HN',
        name: 'Honduras'
      }]
    }, {
      id: 'HRK',
      name: 'label.HRK',
      symbol: 'kn',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_HR.png',
      countryList: [{
        id: 'HR',
        name: 'Croacia'
      }]
    }, {
      id: 'HTG',
      name: 'label.HTG',
      symbol: 'G',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_HT.png',
      countryList: [{
        id: 'HT',
        name: 'Haití'
      }]
    }, {
      id: 'HUF',
      name: 'label.HUF',
      symbol: 'Ft',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_HU.png',
      countryList: [{
        id: 'HU',
        name: 'Hungría'
      }]
    }, {
      id: 'IDR',
      name: 'label.IDR',
      symbol: 'Rp',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_ID.png',
      countryList: [{
        id: 'id',
        name: 'Indonesia'
      }]
    }, {
      id: 'ILS',
      name: 'label.ILS',
      symbol: '₪',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_IL.png',
      countryList: [{
        id: 'IL',
        name: 'Israel'
      }]
    }, {
      id: 'INR',
      name: 'label.INR',
      symbol: '₹',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_IN.png',
      countryList: [{
        id: 'IN',
        name: 'India'
      }]
    }, {
      id: 'IQD',
      name: 'label.IQD',
      symbol: 'ع.د',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_IQ.png',
      countryList: [{
        id: 'IQ',
        name: 'Irak'
      }]
    }, {
      id: 'ISK',
      name: 'label.ISK',
      symbol: 'kr.',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_IS.png',
      countryList: [{
        id: 'IS',
        name: 'Islandia'
      }]
    }, {
      id: 'JMD',
      name: 'label.JMD',
      symbol: 'J$',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_JM.png',
      countryList: [{
        id: 'JM',
        name: 'Jamaica'
      }]
    }, {
      id: 'JOD',
      name: 'label.JOD',
      symbol: 'JD',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_JO.png',
      countryList: [{
        id: 'JO',
        name: 'Jordania'
      }]
    }, {
      id: 'JPY',
      name: 'label.JPY',
      symbol: '¥',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_JP.png',
      countryList: [{
        id: 'JP',
        name: 'Japón'
      }]
    }, {
      id: 'KES',
      name: 'label.KES',
      symbol: 'KSh',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_KE.png',
      countryList: [{
        id: 'KE',
        name: 'Kenia'
      }]
    }, {
      id: 'KGS',
      name: 'label.KGS',
      symbol: 'C',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_KG.png',
      countryList: [{
        id: 'KG',
        name: 'Kirguistán'
      }]
    }, {
      id: 'KHR',
      name: 'label.KHR',
      symbol: '៛',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_KH.png',
      countryList: [{
        id: 'KH',
        name: 'Camboya'
      }]
    }, {
      id: 'KMF',
      name: 'label.KMF',
      symbol: '₣',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_KM.png',
      countryList: [{
        id: 'KM',
        name: 'Comoras'
      }]
    }, {
      id: 'KRW',
      name: 'label.KRW',
      symbol: '₩',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_KR.png',
      countryList: [{
        id: 'KR',
        name: 'Corea del Sur'
      }]
    }, {
      id: 'KWD',
      name: 'label.KWD',
      symbol: 'د.ك',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_KW.png',
      countryList: [{
        id: 'KW',
        name: 'Kuwait'
      }]
    }, {
      id: 'KYD',
      name: 'label.KYD',
      symbol: 'CI$',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_KY.png',
      countryList: [{
        id: 'KY',
        name: 'Islas Caimán'
      }]
    }, {
      id: 'KZT',
      name: 'label.KZT',
      symbol: '₸',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_KZ.png',
      countryList: [{
        id: 'KZ',
        name: 'Kazajistán'
      }]
    }, {
      id: 'LAK',
      name: 'label.LAK',
      symbol: '₭',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_LA.png',
      countryList: [{
        id: 'LA',
        name: 'Laos'
      }]
    }, {
      id: 'LBP',
      name: 'label.LBP',
      symbol: 'ل.ل',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_LB.png',
      countryList: [{
        id: 'LB',
        name: 'Líbano'
      }]
    }, {
      id: 'LKR',
      name: 'label.LKR',
      symbol: 'Rs',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_LK.png',
      countryList: [{
        id: 'LK',
        name: 'Sri Lanka'
      }]
    }, {
      id: 'LRD',
      name: 'label.LRD',
      symbol: 'L$',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_LR.png',
      countryList: [{
        id: 'LR',
        name: 'Liberia'
      }]
    }, {
      id: 'LSL',
      name: 'label.LSL',
      symbol: 'M',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_LS.png',
      countryList: [{
        id: 'LS',
        name: 'Lesoto'
      }]
    }, {
      id: 'LYD',
      name: 'label.LYD',
      symbol: 'ل.د',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_LY.png',
      countryList: [{
        id: 'LY',
        name: 'Libia'
      }]
    }, {
      id: 'MAD',
      name: 'label.MAD',
      symbol: 'درهم',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_MA.png',
      countryList: [{
        id: 'ER',
        name: 'Eritrea'
      }, {
        id: 'MA',
        name: 'Marruecos'
      }]
    }, {
      id: 'MDL',
      name: 'label.MDL',
      symbol: 'L',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_MD.png',
      countryList: [{
        id: 'MD',
        name: 'Moldavia'
      }]
    }, {
      id: 'MGA',
      name: 'label.MGA',
      symbol: 'Ar',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_MG.png',
      countryList: [{
        id: 'MG',
        name: 'Madagascar'
      }]
    }, {
      id: 'MKD',
      name: 'label.MKD',
      symbol: 'Ден',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_MK.png',
      countryList: [{
        id: 'MK',
        name: 'Macedonia'
      }]
    }, {
      id: 'MMK',
      name: 'label.MMK',
      symbol: 'K',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_MM.png',
      countryList: [{
        id: 'MM',
        name: 'Myanmar'
      }]
    }, {
      id: 'MNT',
      name: 'label.MNT',
      symbol: '₮',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_MN.png',
      countryList: [{
        id: 'MN',
        name: 'Mongolia'
      }]
    }, {
      id: 'MOP',
      name: 'label.MOP',
      symbol: 'MOP$',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_MO.png',
      countryList: [{
        id: 'MO',
        name: 'Macao'
      }]
    }, {
      id: 'MRU',
      name: 'label.MRU',
      symbol: 'UM',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_MR.png',
      countryList: [{
        id: 'MR',
        name: 'Mauritania'
      }]
    }, {
      id: 'MUR',
      name: 'label.MUR',
      symbol: '₨',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_MU.png',
      countryList: [{
        id: 'MU',
        name: 'Mauricio'
      }]
    }, {
      id: 'MVR',
      name: 'label.MVR',
      symbol: 'Rf',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_MV.png',
      countryList: [{
        id: 'MV',
        name: 'Maldivas'
      }]
    }, {
      id: 'MWK',
      name: 'label.MWK',
      symbol: 'MK',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_MW.png',
      countryList: [{
        id: 'MW',
        name: 'Malaui'
      }]
    }, {
      id: 'MXN',
      name: 'label.MXN',
      symbol: 'Mx$',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_MX.png',
      countryList: [{
        id: 'MX',
        name: 'México'
      }]
    }, {
      id: 'MYR',
      name: 'label.MYR',
      symbol: 'RM',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_MY.png',
      countryList: [{
        id: 'MY',
        name: 'Malasia'
      }]
    }, {
      id: 'MZN',
      name: 'label.MZN',
      symbol: 'MTn',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_MZ.png',
      countryList: [{
        id: 'MZ',
        name: 'Mozambique'
      }]
    }, {
      id: 'NAD',
      name: 'label.NAD',
      symbol: 'N$',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_NA.png',
      countryList: [{
        id: 'NA',
        name: 'Namibia'
      }]
    }, {
      id: 'NGN',
      name: 'label.NGN',
      symbol: '₦',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_NG.png',
      countryList: [{
        id: 'NG',
        name: 'Nigeria'
      }]
    }, {
      id: 'NIO',
      name: 'label.NIO',
      symbol: 'C$',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_NI.png',
      countryList: [{
        id: 'NI',
        name: 'Nicaragua'
      }]
    }, {
      id: 'NOK',
      name: 'label.NOK',
      symbol: 'kr',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_NO.png',
      countryList: [{
        id: 'BV',
        name: 'Isla Bouvet'
      }, {
        id: 'NO',
        name: 'Noruega'
      }, {
        id: 'SJ',
        name: 'Svalbard y Jan Mayen'
      }]
    }, {
      id: 'NPR',
      name: 'label.NPR',
      symbol: 'Rs',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_NP.png',
      countryList: [{
        id: 'NP',
        name: 'Nepal'
      }]
    }, {
      id: 'NZD',
      name: 'label.NZD',
      symbol: 'NZ$',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_NZ.png',
      countryList: [{
        id: 'CK',
        name: 'Islas Cook'
      }, {
        id: 'NU',
        name: 'Niue'
      }, {
        id: 'NZ',
        name: 'Nueva Zelanda'
      }, {
        id: 'PN',
        name: 'Islas Pitcairn'
      }, {
        id: 'TK',
        name: 'Tokelau'
      }]
    }, {
      id: 'OMR',
      name: 'label.OMR',
      symbol: 'ر.ع.',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_OM.png',
      countryList: [{
        id: 'OM',
        name: 'Omán'
      }]
    }, {
      id: 'PAB',
      name: 'label.PAB',
      symbol: 'B/.',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_PA.png',
      countryList: [{
        id: 'PA',
        name: 'Panamá'
      }]
    }, {
      id: 'PEN',
      name: 'label.PEN',
      symbol: 'S/',
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_PE.png',
      countryList: [{
        id: 'PE',
        name: 'Perú'
      }]
    }, {
      id: 'PGK',
      name: 'label.PGK',
      symbol: 'K',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_PG.png',
      countryList: [{
        id: 'PG',
        name: 'Papúa Nueva Guinea'
      }]
    }, {
      id: 'PHP',
      name: 'label.PHP',
      symbol: '₱',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_PH.png',
      countryList: [{
        id: 'PH',
        name: 'Filipinas'
      }]
    }, {
      id: 'PKR',
      name: 'label.PKR',
      symbol: 'Rs',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_PK.png',
      countryList: [{
        id: 'PK',
        name: 'Pakistán'
      }]
    }, {
      id: 'PLN',
      name: 'label.PLN',
      symbol: 'zł',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_PL.png',
      countryList: [{
        id: 'PL',
        name: 'Polonia'
      }]
    }, {
      id: 'PYG',
      name: 'label.PYG',
      symbol: '₲',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_PY.png',
      countryList: [{
        id: 'PY',
        name: 'Paraguay'
      }]
    }, {
      id: 'QAR',
      name: 'label.QAR',
      symbol: 'ر.ق',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_QA.png',
      countryList: [{
        id: 'QA',
        name: 'Catar'
      }]
    }, {
      id: 'RON',
      name: 'label.RON',
      symbol: 'L',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_RO.png',
      countryList: [{
        id: 'RO',
        name: 'Rumania'
      }]
    }, {
      id: 'RSD',
      name: 'label.RSD',
      symbol: 'дин',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_RS.png',
      countryList: [{
        id: 'RS',
        name: 'Serbia'
      }]
    }, {
      id: 'RUB',
      name: 'label.RUB',
      symbol: 'руб',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_RU.png',
      countryList: [{
        id: 'RU',
        name: 'Rusia'
      }]
    }, {
      id: 'RWF',
      name: 'label.RWF',
      symbol: 'RF',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_RW.png',
      countryList: [{
        id: 'RW',
        name: 'Ruanda'
      }]
    }, {
      id: 'SAR',
      name: 'label.SAR',
      symbol: 'SR',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_SA.png',
      countryList: [{
        id: 'SA',
        name: 'Arabia Saudita'
      }]
    }, {
      id: 'SBD',
      name: 'label.SBD',
      symbol: 'SI$',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_SB.png',
      countryList: [{
        id: 'SB',
        name: 'Islas Salomón'
      }]
    }, {
      id: 'SCR',
      name: 'label.SCR',
      symbol: 'SR',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_SC.png',
      countryList: [{
        id: 'SC',
        name: 'Seychelles'
      }]
    }, {
      id: 'SEK',
      name: 'label.SEK',
      symbol: 'kr',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_SE.png',
      countryList: [{
        id: 'SE',
        name: 'Suecia'
      }]
    }, {
      id: 'SGD',
      name: 'label.SGD',
      symbol: 'S$',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_SG.png',
      countryList: [{
        id: 'SG',
        name: 'Singapur'
      }]
    }, {
      id: 'SHP',
      name: 'label.SHP',
      symbol: '£',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_SH.png',
      countryList: [{
        id: 'SH',
        name: 'Santa Elena, Ascensión y Tristán de Acuña'
      }]
    }, {
      id: 'SLL',
      name: 'label.SLL',
      symbol: 'Le',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_SL.png',
      countryList: [{
        id: 'SL',
        name: 'Sierra Leona'
      }]
    }, {
      id: 'SOS',
      name: 'label.SOS',
      symbol: 'Sh.So.',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_SO.png',
      countryList: [{
        id: 'SO',
        name: 'Somalia'
      }]
    }, {
      id: 'SRD',
      name: 'label.SRD',
      symbol: 'Sr$',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_SR.png',
      countryList: [{
        id: 'SR',
        name: 'Surinam'
      }]
    }, {
      id: 'SSP',
      name: 'label.SSP',
      symbol: 'SS£',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_SS.png',
      countryList: [{
        id: 'SS',
        name: 'Sudán del Sur'
      }]
    }, {
      id: 'STN',
      name: 'label.STN',
      symbol: 'Db',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_ST.png',
      countryList: [{
        id: 'ST',
        name: 'Santo Tomé y Príncipe'
      }]
    }, {
      id: 'SVC',
      name: 'label.SVC',
      symbol: '₡',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_SV.png',
      countryList: [{
        id: 'SV',
        name: 'El Salvador'
      }]
    }, {
      id: 'SZL',
      name: 'label.SZL',
      symbol: 'E',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_SZ.png',
      countryList: [{
        id: 'SZ',
        name: 'Suazilandia'
      }]
    }, {
      id: 'THB',
      name: 'label.THB',
      symbol: '฿',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_TH.png',
      countryList: [{
        id: 'TH',
        name: 'Tailandia'
      }]
    }, {
      id: 'TJS',
      name: 'label.TJS',
      symbol: 'TJS',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_TJ.png',
      countryList: [{
        id: 'TJ',
        name: 'Tayikistán'
      }]
    }, {
      id: 'TMT',
      name: 'label.TMT',
      symbol: 'ман.',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_TM.png',
      countryList: [{
        id: 'TM',
        name: 'Turkmenistán'
      }]
    }, {
      id: 'TND',
      name: 'label.TND',
      symbol: 'د.ت',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_TN.png',
      countryList: [{
        id: 'TN',
        name: 'Túnez'
      }]
    }, {
      id: 'TOP',
      name: 'label.TOP',
      symbol: 'T$',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_TO.png',
      countryList: [{
        id: 'TO',
        name: 'Tonga'
      }]
    }, {
      id: 'TRY',
      name: 'label.TRY',
      symbol: 'TL',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_TR.png',
      countryList: [{
        id: 'TR',
        name: 'Turquía'
      }]
    }, {
      id: 'TTD',
      name: 'label.TTD',
      symbol: 'TT$',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_TT.png',
      countryList: [{
        id: 'TT',
        name: 'Trinidad y Tobago'
      }]
    }, {
      id: 'TWD',
      name: 'label.TWD',
      symbol: 'NT$',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_TW.png',
      countryList: [{
        id: 'TW',
        name: 'Taiwán'
      }]
    }, {
      id: 'TZS',
      name: 'label.TZS',
      symbol: 'TSh',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_TZ.png',
      countryList: [{
        id: 'TZ',
        name: 'Tanzania'
      }]
    }, {
      id: 'UAH',
      name: 'label.UAH',
      symbol: '₴',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_UA.png',
      countryList: [{
        id: 'UA',
        name: 'Ucrania'
      }]
    }, {
      id: 'UGX',
      name: 'label.UGX',
      symbol: 'USh',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_UG.png',
      countryList: [{
        id: 'UG',
        name: 'Uganda'
      }]
    }, {
      id: 'USD',
      name: 'label.USD',
      symbol: '$',
      rightAlignedCurrency: false,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_US.png',
      countryList: [{
        id: 'AS',
        name: 'Samoa Americana'
      }, {
        id: 'BQ',
        name: 'Bonaire, San Eustaquio y Saba'
      }, {
        id: 'EC',
        name: 'Ecuador'
      }, {
        id: 'FM',
        name: 'Micronesia'
      }, {
        id: 'GU',
        name: 'Guam'
      }, {
        id: 'IO',
        name: 'Territorio Británico del Océano Índico'
      }, {
        id: 'MH',
        name: 'Islas Marshall'
      }, {
        id: 'MP',
        name: 'Islas Marianas del Norte'
      }, {
        id: 'PR',
        name: 'Puerto Rico'
      }, {
        id: 'PW',
        name: 'Palaos'
      }, {
        id: 'TC',
        name: 'Islas Turcas y Caicos'
      }, {
        id: 'TL',
        name: 'Timor Oriental'
      }, {
        id: 'UM',
        name: 'Islas ultramarinas de Estados Unidos'
      }, {
        id: 'US',
        name: 'Estados Unidos'
      }, {
        id: 'VG',
        name: 'Islas Vírgenes Británicas'
      }, {
        id: 'VI',
        name: 'Islas Vírgenes de los Estados Unidos'
      }, {
        id: 'CU',
        name: 'Cuba'
      }]
    }, {
      id: 'UYU',
      name: 'label.UYU',
      symbol: '$',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_UY.png',
      countryList: [{
        id: 'UY',
        name: 'Uruguay'
      }]
    }, {
      id: 'UZS',
      name: 'label.UZS',
      symbol: 'UZS',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_UZ.png',
      countryList: [{
        id: 'UZ',
        name: 'Uzbekistán'
      }]
    }, {
      id: 'VES',
      name: 'label.VES',
      symbol: 'Bs.',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_VE.png',
      countryList: [{
        id: 'VE',
        name: 'Venezuela'
      }]
    }, {
      id: 'VND',
      name: 'label.VND',
      symbol: '₫',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_VN.png',
      countryList: [{
        id: 'VN',
        name: 'Vietnam'
      }]
    }, {
      id: 'VUV',
      name: 'label.VUV',
      symbol: 'VT',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_VU.png',
      countryList: [{
        id: 'VU',
        name: 'Vanuatu'
      }]
    }, {
      id: 'WST',
      name: 'label.WST',
      symbol: 'WS$',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_WS.png',
      countryList: [{
        id: 'WS',
        name: 'Samoa'
      }]
    }, {
      id: 'XAF',
      name: 'label.XAF',
      symbol: 'FCFA',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_earth.png',
      countryList: [{
        id: 'CF',
        name: 'República Centroafricana'
      }, {
        id: 'CG',
        name: 'República del Congo'
      }, {
        id: 'CM',
        name: 'Camerún'
      }, {
        id: 'GA',
        name: 'Gabón'
      }, {
        id: 'GQ',
        name: 'Guinea Ecuatorial'
      }, {
        id: 'TD',
        name: 'Chad'
      }]
    }, {
      id: 'XCD',
      name: 'label.XCD',
      symbol: 'EC$',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_earth.png',
      countryList: [{
        id: 'AG',
        name: 'Antigua y Barbuda'
      }, {
        id: 'AI',
        name: 'Anguila'
      }, {
        id: 'DM',
        name: 'Dominica'
      }, {
        id: 'GD',
        name: 'Granada'
      }, {
        id: 'KN',
        name: 'San Cristóbal y Nieves'
      }, {
        id: 'LC',
        name: 'Santa Lucía'
      }, {
        id: 'MS',
        name: 'Montserrat'
      }, {
        id: 'VC',
        name: 'San Vicente y las Granadinas'
      }]
    }, {
      id: 'XOF',
      name: 'label.XOF',
      symbol: 'FCFA',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_earth.png',
      countryList: [{
        id: 'BF',
        name: 'Burkina Faso'
      }, {
        id: 'BJ',
        name: 'Benín'
      }, {
        id: 'CI',
        name: 'Costa de Marfil'
      }, {
        id: 'GW',
        name: 'Guinea-Bisáu'
      }, {
        id: 'ML',
        name: 'Malí'
      }, {
        id: 'NE',
        name: 'Níger'
      }, {
        id: 'SN',
        name: 'Senegal'
      }, {
        id: 'TG',
        name: 'Togo'
      }]
    }, {
      id: 'XPF',
      name: 'label.XPF',
      symbol: '₣',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_earth.png',
      countryList: [{
        id: 'NC',
        name: 'Nueva Caledonia'
      }, {
        id: 'PF',
        name: 'Polinesia Francesa'
      }, {
        id: 'WF',
        name: 'Wallis y Futuna'
      }]
    }, {
      id: 'YER',
      name: 'label.YER',
      symbol: 'ريال',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_YE.png',
      countryList: [{
        id: 'YE',
        name: 'Yemen'
      }]
    }, {
      id: 'ZAR',
      name: 'label.ZAR',
      symbol: 'R',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_ZA.png',
      countryList: [{
        id: 'ZA',
        name: 'Sudáfrica'
      }]
    }, {
      id: 'ZMW',
      name: 'label.ZMW',
      symbol: 'K',
      rightAlignedCurrency: true,
      image: 'https://assets.caasbbva.com/spain/app/regional-settings/flags/flag_ZM.png',
      countryList: [{
        id: 'ZM',
        name: 'Zambia'
      }]
    }]
  };
  _exports.AVAILABLE_CURRENCY = AVAILABLE_CURRENCY;
});