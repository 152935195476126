define("@woody-lite/addon-feedback/decorators/fetch-opinator-configuration", ["exports", "@ember/service", "@ember/object"], function (_exports, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = fetchOpinatorConfiguration;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  function fetchOpinatorConfiguration(Target) {
    var _class, _descriptor;
    const Klass = (_class = class FetchOpinatorConfiguration extends Target {
      constructor() {
        super(...arguments);
        _initializerDefineProperty(this, "opinatorConfiguration", _descriptor, this);
      }
      async didTransition() {
        if (super.didTransition) {
          await super.didTransition(...arguments);
        }
        try {
          const link = await this.opinatorConfiguration.fetch(this.fullRouteName, this.feedbackFormId, this.feedbackType);
          this.feedbackUrl = link;
        } catch {
          // Noop
        }
      }
    }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "opinatorConfiguration", [_service.inject], {
      configurable: true,
      enumerable: true,
      writable: true,
      initializer: null
    }), _applyDecoratedDescriptor(_class.prototype, "didTransition", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didTransition"), _class.prototype)), _class);
    Object.defineProperty(Klass, 'name', {
      value: Target.name,
      writable: true,
      configurable: true
    });
    return Klass;
  }
});