define("@woody-lite/engine-security-module/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _routes.default)(function () {
    this.route('biometrics-access');
    this.route('biometrics-all-in-one');
    this.route('access-with-biometrics');
    this.route('confirmation');
    this.route('privacy-personal-data');
    this.route('secure-devices', function () {
      this.route('index', {
        path: '/'
      });
      this.route('detail', {
        path: '/:device_id/'
      });
    });
  });
  _exports.default = _default;
});