define("@woody-mrs-potato/core-storages/storages/memory", ["exports", "@woody-mrs-potato/core-storages/storages/base", "@ember/object"], function (_exports, _base, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  class MemoryStorage extends _base.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "adapter", window.localStorage);
      _defineProperty(this, "namespace", 'memory');
    }
    init() {
      super.init(...arguments);
      this.initialContent = _object.default.create();
      (0, _object.set)(this, 'content', this.initialContent);
    }
  }
  _exports.default = MemoryStorage;
});