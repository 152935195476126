define("@woody-lite/core-session/helpers/access-method-info", ["exports", "@ember/component/helper", "@woody-lite/core-session/constants"], function (_exports, _helper, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.INFO = void 0;
  _exports.accessMethodInfo = accessMethodInfo;
  _exports.default = void 0;
  const {
    TOUCH_ID,
    BIOMETRICS,
    FACE_ID
  } = _constants.ACCESS_METHODS;
  const INFO = {
    [BIOMETRICS]: {
      titleLabel: 'label.accessTypes.BIOMETRICS.title',
      descriptionLabel: 'label.accessTypes.BIOMETRICS.description',
      icon: 'icon-ui-biometric'
    },
    [TOUCH_ID]: {
      titleLabel: 'label.accessTypes.TOUCH_ID.title',
      descriptionLabel: 'label.accessTypes.TOUCH_ID.description',
      icon: 'icon-ui-biometric'
    },
    [FACE_ID]: {
      titleLabel: 'label.accessTypes.FACE_ID.title',
      descriptionLabel: 'label.accessTypes.FACE_ID.description',
      icon: 'icon-my-profile'
    }
  };
  _exports.INFO = INFO;
  function accessMethodInfo(accessMethod) {
    return INFO[accessMethod];
  }
  var _default = (0, _helper.helper)(_ref => {
    let [value] = _ref;
    return accessMethodInfo(value);
  });
  _exports.default = _default;
});