define("@woody-lite/addon-feedback/decorators/on-leave-feedback", ["exports", "@ember/service", "@ember/object", "@ember/application", "@ember/utils"], function (_exports, _service, _object, _application, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = onLeaveFeedback;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  function onLeaveFeedback(Target) {
    var _class, _descriptor;
    const Klass = (_class = class WillTransition extends Target {
      constructor() {
        super(...arguments);
        _initializerDefineProperty(this, "feedback", _descriptor, this);
      }
      async willTransition(transition) {
        if (super.willTransition) {
          await super.willTransition(...arguments);
        }
        const controller = this.controllerFor(this.routeName);
        this.feedback.isRunning = true;
        try {
          await this.triggerLeavingRouteFeedback({
            targetRouteName: transition.targetName,
            feedbackUrl: this.feedbackUrl,
            feedbackType: this.feedbackType,
            feedbackLang: this.feedbackLang,
            stars: (0, _object.get)(controller, 'stars'),
            hideFeedback: (0, _object.get)(controller, 'hideFeedback')
          });
        } catch {
          //
        }
        this.feedback.isRunning = false;
      }
      async triggerLeavingRouteFeedback(_ref) {
        let {
          targetRouteName,
          feedbackUrl,
          feedbackType,
          feedbackLang,
          stars,
          hideFeedback
        } = _ref;
        const currentRouteName = this.fullRouteName;
        const destinationHasFeedback = this.checkFeedbackBehaviour(targetRouteName);
        if (destinationHasFeedback && !hideFeedback) {
          await this.feedback.openModal(currentRouteName, feedbackUrl, feedbackType, feedbackLang, stars);
        }
      }
      checkFeedbackBehaviour(targetRoute) {
        const appConfig = (0, _application.getOwner)(this).resolveRegistration('config:environment');
        const destinationRoutes = appConfig.APP.DESTINATION_ROUTES;
        const routeName = destinationRoutes.filter(route => route === targetRoute);
        return !(0, _utils.isEmpty)(routeName);
      }
    }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "feedback", [_service.inject], {
      configurable: true,
      enumerable: true,
      writable: true,
      initializer: null
    }), _applyDecoratedDescriptor(_class.prototype, "willTransition", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "willTransition"), _class.prototype)), _class);
    Object.defineProperty(Klass, 'name', {
      value: Target.name,
      writable: true,
      configurable: true
    });
    return Klass;
  }
});