define("@woody-mrs-potato/addon-slinky/services/slinky", ["exports", "@woody-mrs-potato/addon-slinky/core/slinky", "@woody-mrs-potato/addon-slinky/wires/android", "@woody-mrs-potato/addon-slinky/wires/ios", "ember", "@ember/service", "@glimmer/tracking", "@ember/utils"], function (_exports, _slinky, _android, _ios, _ember, _service, _tracking, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let pending = 0;

  // istanbul ignore else: this is always true on tests
  if (_ember.default.Test) {
    // eslint-disable-next-line ember/new-module-imports
    _ember.default.Test.registerWaiter(() => pending === 0);
  }
  let SlinkyService = (_class = class SlinkyService extends _service.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "slinky", void 0);
      _initializerDefineProperty(this, "_isConnected", _descriptor, this);
      this.slinky = new _slinky.default();
      this.slinky.register(new _android.default());
      this.slinky.register(new _ios.default());
    }
    get isConnected() {
      return (0, _utils.isPresent)(this._isConnected) ? this._isConnected : this.slinky.isConnected;
    }
    set isConnected(value) {
      this._isConnected = value;
    }
    async send() {
      pending++;
      try {
        return await this.slinky.send(...arguments);
      } finally {
        pending--;
      }
    }
    on() {
      return this.slinky.on(...arguments);
    }
    off() {
      return this.slinky.off(...arguments);
    }
    trigger() {
      return this.slinky.trigger(...arguments);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_isConnected", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = SlinkyService;
});