define("@woody-lite/addon-conversations/enaxs/messages", ["exports", "@woody-lite/core-session/resources/client-enax", "@woody-mrs-potato/core-http/services/http"], function (_exports, _clientEnax, _http) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  class MessagesEnax extends _clientEnax.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "cacheStrategy", _http.CACHE_STRATEGIES.NETWORK_FIRST);
      _defineProperty(this, "smc", {
        GET: 'SMC201400310',
        POST: 'SMC201400311',
        PUT: 'SMC201400312',
        DELETE: 'SMC201600038'
      });
      _defineProperty(this, "endpoint", {
        GET: '/messageThreads/V01/:threadId/messages/:messageId',
        POST: '/messageThreads/V01/:threadId/messages/',
        PUT: '/messageThreads/V01/:threadId/messages/:messageId',
        DELETE: '/messageThreads/V01/:threadId/messages/:messageId'
      });
    }
    async done() {
      const response = await super.done(...arguments);
      return response === null || response === void 0 ? void 0 : response.data;
    }
  }
  _exports.default = MessagesEnax;
});