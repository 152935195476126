define("@woody-lite/addon-crm-campaigns/services/userevents-dispatcher", ["exports", "ember-collector-dispatcher/services/dispatcher", "@ember/service", "@woody-lite/core-session/decorators/session"], function (_exports, _dispatcher, _service, _session) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const TIMEOUT = 3000;
  const CHUNK_SIZE = 5;
  let UserEventsDispatcherService = (_dec = (0, _session.default)('client'), _dec2 = (0, _service.inject)('userevents-collector'), (_class = class UserEventsDispatcherService extends _dispatcher.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentSession", _descriptor, this);
      _initializerDefineProperty(this, "enax", _descriptor2, this);
      _initializerDefineProperty(this, "provider", _descriptor3, this);
      _initializerDefineProperty(this, "collector", _descriptor4, this);
      _defineProperty(this, "maxTimeout", TIMEOUT);
      _defineProperty(this, "maxConcurrent", CHUNK_SIZE);
    }
    async getChannel() {
      const device = await this.provider.pull('device', 'getDevice');
      const deviceSO = device.isAndroid ? 'Android' : 'iOS';
      const classification = device.isNative ? deviceSO : 'Mobile';
      return {
        classification
      };
    }
    async sendEvents(events) {
      var _this$currentSession;
      const customerId = (_this$currentSession = this.currentSession) === null || _this$currentSession === void 0 ? void 0 : _this$currentSession.id;
      const params = {
        channel: await this.getChannel(),
        customerId,
        events
      };
      return this.enax.POST('user-events', params);
    }
    async dispatch(events) {
      if (this.isDestroyed) {
        await this.stop();
        return events;
      }
      try {
        await this.sendEvents(events);
        return [];
      } catch {
        await this.stop();
        return events;
      }
    }
    pushEvent(event) {
      this.collector.push(event);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentSession", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "enax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "collector", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = UserEventsDispatcherService;
});