define("@woody-lite/core-session/enaxs/refresh-granting-ticket", ["exports", "@woody-lite/core-session/resources/client-enax", "@woody-mrs-potato/core-http/services/http", "@ember/object"], function (_exports, _clientEnax, _http, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  class RefreshGrantingTicketsEnax extends _clientEnax.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "endpoint", '/grantingTicketActions/V01/refreshGrantingTicket');
      _defineProperty(this, "smc", 'SMC201700504');
      _defineProperty(this, "cacheStrategy", _http.CACHE_STRATEGIES.NETWORK_ONLY);
    }
    get params() {
      return {
        customerId: (0, _object.get)(this.currentSession, 'id')
      };
    }
  }
  _exports.default = RefreshGrantingTicketsEnax;
});