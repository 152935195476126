define("@woody-lite/engine-promotions/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _routes.default)(function () {
    this.route('index', {
      path: '/'
    });
    this.route('brand-promo-detail');
    this.route('lottery-promo-detail');
    this.route('lottery-promo-movements');
    this.route('bollo-free-promo-detail');
    this.route('bollo-free-promo-detail-movements');
  });
  _exports.default = _default;
});