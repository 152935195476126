define("@woody-lite/core-session/repositories/access-method", ["exports", "ember-object-data-model/repositories/repository", "ember-object-data-model/models/base-array", "@ember/service", "@woody-lite/core-session/decorators/session", "@ember/object", "@woody-lite/core-session/constants"], function (_exports, _repository, _baseArray, _service, _session, _object, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const VALID_ACCESS_METHODS = Object.keys(_constants.ACCESS_METHODS);
  let AccessMethodRepository = (_dec = (0, _session.default)('client'), (_class = class AccessMethodRepository extends _repository.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "provider", _descriptor, this);
      _initializerDefineProperty(this, "slinky", _descriptor2, this);
      _initializerDefineProperty(this, "enax", _descriptor3, this);
      _initializerDefineProperty(this, "veil", _descriptor4, this);
      _initializerDefineProperty(this, "currentSession", _descriptor5, this);
    }
    async findAll() {
      const capabilities = await this.provider.pull('device', 'getCapabilities');
      const authenticators = await this.callRepository('access-method', 'getAuthenticators');
      const currentAuthenticators = authenticators.filter(item => (0, _object.get)(item, 'isCurrentDevice'));
      const content = VALID_ACCESS_METHODS.map(methodName => {
        const method = _constants.METHODS[methodName];
        const activationValidationFlag = (0, _object.get)(method, 'checks.capabilities.activation');
        const canBeActivated = (0, _object.get)(capabilities, activationValidationFlag);
        const currentAuthenticator = currentAuthenticators.find(item => (0, _object.get)(item, 'type') === methodName);
        const supportValidationFlag = (0, _object.get)(method, 'checks.capabilities.support');
        const supportValidation = (0, _object.get)(capabilities, supportValidationFlag);
        return this.createEntry('access-method', methodName, {
          tokenName: method.tokenName,
          canBeActivated,
          isConsultive: Boolean(method.isConsultive),
          isSupported: supportValidation,
          isEnabled: Boolean(canBeActivated && currentAuthenticator)
        });
      });
      return _baseArray.default.create({
        content
      });
    }
    async getAuthenticators() {
      const devices = await this.callRepository('access-method', 'getDevices');
      const content = devices.reduce((acc, device) => [...acc, ...(0, _object.get)(device, 'authenticators')], []);
      return _baseArray.default.create({
        content
      });
    }
    async getDevices() {
      let content = [];
      const params = {
        originAppId: (0, _object.get)(this.currentSession, 'consumerId')
      };
      const data = await this.enax.GET('device-biometric-enrollment', {
        params
      });
      const {
        authenticators
      } = await this.slinky.send('Fido.getAuthenticators').catch(() => ({
        authenticators: []
      }));
      const nativeAuthenticators = authenticators;
      content = (data || []).map(item => {
        const authenticatorsModels = item.authenticators.reduce((acc, authenticator) => {
          const nativeAuthenticator = nativeAuthenticators.filter(elem => authenticator.registrationId === elem.registrationId);
          return [...acc, ...this._attachAllDevicesFromNative(nativeAuthenticator)];
        }, []);
        return this.createEntry('devices-enrolled', item.device.internalDeviceId, {
          authenticators: authenticatorsModels,
          ...item.device
        });
      });
      return _baseArray.default.create({
        content
      });
    }
    async activate(accessMethod) {
      if (!((0, _object.get)(accessMethod, 'isSupported') && (0, _object.get)(accessMethod, 'canBeActivated'))) {
        throw new Error('Access method cannot be activated');
      }
      await this._enroll(accessMethod.id);
      (0, _object.set)(accessMethod, 'isEnabled', true);
      return accessMethod;
    }
    async deactivate(accessMethod) {
      const authenticatorType = accessMethod.id;
      await this.slinky.send('Fido.deleteAuthenticator', {
        authenticatorType
      });
      (0, _object.set)(accessMethod, 'isEnabled', false);
    }
    _attachAllDevicesFromNative(nativeAuthenticator) {
      return nativeAuthenticator.map((authenticatorFiltered, index) => this.createEntry('authenticator', "".concat(authenticatorFiltered.registrationId, "_").concat(index), {
        ...authenticatorFiltered,
        isCurrentDevice: authenticatorFiltered === null || authenticatorFiltered === void 0 ? void 0 : authenticatorFiltered.isCurrentDevice,
        type: authenticatorFiltered === null || authenticatorFiltered === void 0 ? void 0 : authenticatorFiltered.type
      }));
    }
    async _enroll(authenticatorType) {
      const {
        status,
        payload
      } = await this.slinky.send('Fido.initRegister', {
        authenticatorType
      });
      if (status === _constants.INIT_STATUS.NEED_FINALIZE) {
        let message;
        try {
          const data = await this.enax.POST('device-biometric-enrollment', payload);
          message = data === null || data === void 0 ? void 0 : data.message;
        } catch (e) {
          await this.slinky.send('Fido.finishRegister', {
            e
          });
          throw new Error('Access method cannot be activated');
        }
        await this._finishRegister(message);
      }
    }
    async _finishRegister(message) {
      try {
        this.veil.add();
        await this.slinky.send('Fido.finishRegister', {
          message
        });
      } finally {
        this.veil.remove();
      }
    }
    async deleteDevice(device) {
      await this.enax.DELETE('device-biometric-enrollment', null, {
        params: {
          internalDeviceId: (0, _object.get)(device, 'internalDeviceId'),
          originAppId: (0, _object.get)(this.currentSession, 'consumerId')
        }
      });
      device.unsync();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "slinky", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "enax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "veil", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "currentSession", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AccessMethodRepository;
});