define("@woody-lite/addon-non-financial-services/enaxs/unsubscribe-non-financial-service", ["exports", "@woody-lite/core-session/resources/client-enax", "@woody-lite/core-session/decorators/session"], function (_exports, _clientEnax, _session) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let UnsuscribeNonFinancialServiceEnax = (_dec = (0, _session.default)('client'), (_class = class UnsuscribeNonFinancialServiceEnax extends _clientEnax.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentSession", _descriptor, this);
      _defineProperty(this, "endpoint", '/non-financial-services/v0/non-financial-services/:customerId-:evidenceId-:version/unsubscribe');
      _defineProperty(this, "smc", 'SMC201800413');
    }
    get customerId() {
      return this.currentSession.id;
    }
    serialize(data) {
      return {
        reason: this.reason,
        nonFinancialProduct: {
          id: this.evidenceId,
          version: this.version
        },
        targetUserId: this.currentSession.id,
        ...data
      };
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentSession", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = UnsuscribeNonFinancialServiceEnax;
});