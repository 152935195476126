define("@woody-lite/engine-saving-goals/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _routes.default)(function () {
    this.route('index', {
      path: '/'
    });
    this.route('detail');
    this.route('draft', {
      path: '/draft/:mode'
    });
    this.route('confirmation', {
      path: '/confirmation/:mode'
    });
    this.route('movements');
    this.route('contribution');
    this.route('transfer');
  });
  _exports.default = _default;
});