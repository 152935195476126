define("@woody-lite/core-session/components/modal-face-recognition-error", ["exports", "@woody-lite/core-foundation/components/modal", "@ember/object", "@woody-lite/core-session/templates/modal-face-recognition-error"], function (_exports, _modal, _object, _modalFaceRecognitionError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const ERRORS = {
    permissions: {
      title: 'label.faceRecognition.errors.permissions.title',
      text: 'label.faceRecognition.errors.permissions.text'
    },
    generic: {
      title: 'label.faceRecognition.errors.generic.title',
      text: 'label.faceRecognition.errors.generic.text'
    },
    cancelled: {
      title: 'label.faceRecognition.errors.cancelled.title',
      text: 'label.faceRecognition.errors.cancelled.text'
    }
  };
  let ModalFaceRecognitionErrorComponent = (_class = class ModalFaceRecognitionErrorComponent extends _modal.default {
    constructor() {
      var _this$veil;
      super(...arguments);
      _defineProperty(this, "layout", _modalFaceRecognitionError.default);
      if ((_this$veil = this.veil) !== null && _this$veil !== void 0 && _this$veil.show) {
        this.veil.remove();
      }
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      const {
        errorType
      } = this.model.options;
      this.errorType = errorType;
      this.title = ERRORS[this.errorType].title;
      this.text = ERRORS[this.errorType].text;
    }
    closeModal() {
      return this.reject();
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "closeModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype)), _class);
  _exports.default = ModalFaceRecognitionErrorComponent;
});