define("@woody-lite/addon-feedback/enaxs/feedback", ["exports", "@woody-lite/core-session/resources/public-enax"], function (_exports, _publicEnax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  class FeedbackEnaxResource extends _publicEnax.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "smc", 'SMC201500287');
      _defineProperty(this, "endpoint", '/customerSurveyConfigurations/V01');
    }
    async done() {
      const {
        data
      } = await super.done(...arguments);
      return data;
    }
  }
  _exports.default = FeedbackEnaxResource;
});