define("@woody-lite/core-session/repositories/valid-cards", ["exports", "ember-object-data-model/repositories/repository", "@ember/service", "ember-object-data-model/models/base-array", "@ember/object"], function (_exports, _repository, _service, _baseArray, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ValidCardsRepository = (_class = class ValidCardsRepository extends _repository.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "enax", _descriptor, this);
      _initializerDefineProperty(this, "provider", _descriptor2, this);
    }
    async getValidCards() {
      try {
        const cards = await this.provider.pull('cards', 'allCards');
        const validCards = await this.enax.GET('valid-cards');
        const indicators = this.normalizeIndicators(validCards);
        const content = cards.filter(card => {
          const id = (0, _object.get)(card, 'id');
          return indicators[id] && indicators[id].CHANNEL_OPERABLE && indicators[id].ACTIVE && !indicators[id].TEMPORAL_BLOCKED && !indicators[id].CONTACLESS_BLOCKED;
        });
        return _baseArray.default.create({
          content
        });
      } catch (e) {
        return _baseArray.default.create({
          content: []
        });
      }
    }
    normalizeIndicators(indicatorsData) {
      return Object.assign(...indicatorsData.map(card => {
        return {
          [card.contract.id]: Object.assign(...card.indicators.map(item => {
            return {
              [item.id]: item.isActive
            };
          }))
        };
      }));
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "enax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = ValidCardsRepository;
});