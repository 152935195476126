define("@woody-lite/core-session/components/modal-safe-tap-settings", ["exports", "@woody-lite/core-foundation/components/modal", "@ember/object", "@ember/runloop", "@ember/service", "@woody-lite/core-session/errors/user-choose-signature-method", "@woody-lite/core-session/templates/modal-safe-tap-settings"], function (_exports, _modal, _object, _runloop, _service, _userChooseSignatureMethod, _modalSafeTapSettings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const DEBOUNCE_INTERACTION = 100;
  let ModalSafeTapSettingComponent = (_class = class ModalSafeTapSettingComponent extends _modal.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "layout", _modalSafeTapSettings.default);
      _initializerDefineProperty(this, "slinky", _descriptor, this);
      _initializerDefineProperty(this, "provider", _descriptor2, this);
      this.onForegroundBinding = this.onUserInteractionDebounce.bind(this);
    }
    async didOpen() {
      super.didOpen(...arguments);
      const device = await this.provider.pull('device', 'getDevice');
      device.on('foreground', this.onForegroundBinding);
    }
    async willDestroy() {
      super.willDestroy(...arguments);
      const device = await this.provider.pull('device', 'getDevice');
      device.off('foreground', this.onForegroundBinding);
    }
    onUserInteractionDebounce() {
      (0, _runloop.debounce)(this, 'onUserInteractionRegister', DEBOUNCE_INTERACTION);
    }
    get canSelecthAnotherType() {
      return this.model.options.canSelecthAnotherType;
    }
    async onUserInteractionRegister() {
      const capabilities = await this.provider.fetch('device', 'getCapabilities');
      const isNfcEnabled = (0, _object.get)(capabilities, 'isNfcEnabled');
      if (isNfcEnabled) {
        this.resolve();
      }
    }
    closeModal() {
      this.reject();
    }
    userChoice() {
      this.reject(new _userChooseSignatureMethod.UserChooseSignatureMethodError());
    }
    openSettings() {
      this.slinky.send('Device.openSettings', {
        screen: 'NFC_SETTINGS'
      });
      this.reject();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "slinky", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "userChoice", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "userChoice"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openSettings", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openSettings"), _class.prototype)), _class);
  _exports.default = ModalSafeTapSettingComponent;
});