define("@woody-lite/core-traceability/utils/get-page-levels", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MAX_PAGE_LEVELS = void 0;
  _exports.default = getPageLevels;
  const MAX_PAGE_LEVELS = 10;
  _exports.MAX_PAGE_LEVELS = MAX_PAGE_LEVELS;
  function getPageLevels(fullPageName) {
    return (fullPageName || '').replace(/@woody-lite\/engine-/, '').replace(/-(?:it|affinity)/, '').replace(/-/g, ' ').split('.');
  }
});