define("@woody-lite/addon-feedback/services/feedback", ["exports", "@ember/service", "@woody-lite/core-session/decorators/session", "@glimmer/tracking"], function (_exports, _service, _session, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const AMBIENT_AUTO = 'auto';
  const DARK = 'dark';
  let FeedbackService = (_dec = (0, _session.default)('client'), (_class = class FeedbackService extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "modal", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "provider", _descriptor3, this);
      _initializerDefineProperty(this, "currentSession", _descriptor4, this);
      _initializerDefineProperty(this, "isRunning", _descriptor5, this);
    }
    // eslint-disable-next-line max-params
    async openModal(currentRoute, feedbackUrl, feedbackType, feedbackLang, stars, additionalParams) {
      if (!feedbackUrl) {
        await this.modal.open('error-form');
        return false;
      }
      let data = await this.getDataForModal(currentRoute, feedbackUrl, feedbackType, feedbackLang, stars);
      data = {
        ...data,
        ...additionalParams
      };
      return this.showFeedback(data);
    }
    async showFeedback(data) {
      let stars = 0;
      try {
        await this.modal.open('feedback', data);
      } catch (e) {
        if (e instanceof Error) {
          stars = 0;
        } else {
          const currentStars = e;
          stars = currentStars || 0;
        }
      }
      return stars;
    }
    async getDataForModal(currentRoute, feedbackUrl, feedbackType, feedbackLang, stars) {
      const {
        isIOS,
        isBrowser,
        ambient,
        isDarkMode,
        isNative,
        deviceAmbient
      } = await this.provider.pull('device', 'getDevice');
      const lang = this.intl.primaryLocale;
      return {
        carry_darkmode: this.getDarkParam({
          ambient,
          isDarkMode,
          isNative,
          deviceAmbient
        }),
        carry_isnative: !isBrowser,
        stars_1: stars || 0,
        sc: this.getCleanRoute(currentRoute),
        ac: feedbackType,
        feedbackUrl,
        isIOS,
        carry_lang: lang,
        feedbackLang
      };
    }
    getDarkParam(_ref) {
      let {
        ambient,
        isDarkMode,
        isNative,
        deviceAmbient
      } = _ref;
      if (ambient === AMBIENT_AUTO && isNative) {
        return deviceAmbient === DARK;
      }
      if (ambient === AMBIENT_AUTO) {
        return isDarkMode;
      }
      return ambient === DARK;
    }
    getCleanRoute(currentRoute) {
      const splitBySlash = currentRoute.split('/');
      if (splitBySlash[1]) {
        return splitBySlash[1].substr(7);
      }
      return currentRoute;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentSession", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isRunning", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class));
  _exports.default = FeedbackService;
});