define("@woody-mrs-potato/core-storages/utils/storage-for", ["exports", "@ember/application", "@ember/debug", "@ember/object"], function (_exports, _application, _debug, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MEMORY = _exports.LOCAL = void 0;
  _exports.default = storageFor;
  const MEMORY = 'memory';
  _exports.MEMORY = MEMORY;
  const LOCAL = 'local';
  _exports.LOCAL = LOCAL;
  function storageFor() {
    let type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : LOCAL;
    (false && !(type) && (0, _debug.assert)('A type must be provided', type));
    return (0, _object.computed)(type, {
      get() {
        const owner = (0, _application.getOwner)(this);
        const storage = owner.lookup("storage:".concat(type));
        (false && !(storage) && (0, _debug.assert)("A storage was not found for type ".concat(type), storage));
        return storage;
      }
    });
  }
});