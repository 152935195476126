define("@woody-lite/core-traceability/instance-initializers/tealium-cookies-tracking", ["exports", "@ember/object", "@ember/test-waiters", "@woody-lite/core-traceability/utils/is-adapter-enabled", "@woody-lite/core-traceability/utils/tealium-context"], function (_exports, _object, _testWaiters, _isAdapterEnabled, _tealiumContext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  const waiter = (0, _testWaiters.buildWaiter)('@woody-lite/core-traceability:tealium-cookies-tracking');
  function setCokiesConsentContext(metrics, cookiesConsent) {
    const consent = cookiesConsent.toJSON();
    if (!(0, _object.get)(cookiesConsent, 'isSettled')) {
      Object.keys(consent).forEach(key => {
        if (typeof consent[key] === 'boolean') {
          consent[key] = true;
        }
      });
    }
    (0, _tealiumContext.setContext)(metrics, 'cookiesConsent', {
      user: {
        device: {
          consent
        }
      }
    });
  }
  async function setupCookiesListener(metrics, provider) {
    const cookiesConsent = await provider.pull('cookies-consent', 'getConsent');
    setCokiesConsentContext(metrics, cookiesConsent);
    cookiesConsent.on('change', () => {
      setCokiesConsentContext(metrics, cookiesConsent);
    });
  }
  async function initialize(instance) {
    const config = instance.resolveRegistration('config:environment');
    if ((0, _isAdapterEnabled.default)(config)) {
      // This condition prevents initializing the adapter
      const provider = instance.lookup('service:provider');
      const metrics = instance.lookup('service:metrics');
      if (metrics && provider) {
        const token = waiter.beginAsync();
        await setupCookiesListener(metrics, provider);
        waiter.endAsync(token);
      }
    }
  }
  var _default = {
    name: 'tealium/cookies-tracking',
    initialize
  };
  _exports.default = _default;
});