define("@woody-lite/addon-conversations/repositories/threads", ["exports", "ember-object-data-model/repositories/repository", "ember-object-data-model/models/base-array", "@woody-lite/core-session/decorators/session", "ember-utils/utils/strings/unserialize-url", "@ember/service", "@ember/object", "@ember/application"], function (_exports, _repository, _baseArray, _session, _unserializeUrl, _service, _object, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const DEFAULT_MANAGER = {
    id: 'ZPHI001'
  };
  let ThreadsRepository = (_dec = (0, _session.default)('client'), (_class = class ThreadsRepository extends _repository.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "enax", _descriptor, this);
      _initializerDefineProperty(this, "provider", _descriptor2, this);
      _initializerDefineProperty(this, "currentSession", _descriptor3, this);
    }
    async getStatus() {
      let data = {};
      const customerId = this.currentSession.id;
      try {
        const params = {
          $fields: 'customerpendingmessagethreads',
          $filter: "(customer.id==".concat(customerId, ")")
        };
        data = await this.enax.GET('threads', {
          params
        });
      } catch {
        //
      }
      return this.createEntry('conversation', customerId, data);
    }
    _buildThreadsPayload(nextPage) {
      let paginationKey = 1;
      if (nextPage) {
        const nextPageUrl = (0, _unserializeUrl.default)(nextPage);
        paginationKey = encodeURIComponent(nextPageUrl.paginationKey);
      }
      return {
        $filter: "(customer.id==".concat(this.currentSession.id, ")"),
        $expands: 'messages',
        paginationKey,
        pageSize: 20
      };
    }
    async _getThreads(next) {
      let content = [];
      let nextPage;
      try {
        var _data$pagination;
        const params = this._buildThreadsPayload(next);
        const data = await this.enax.GET('threads', {
          params
        });
        const normalizer = (0, _application.getOwner)(this).lookup('normalizer:thread-message');
        content = ((data === null || data === void 0 ? void 0 : data.messageThreads) || []).map(thread => {
          const contentMessages = thread.messages.map(message => {
            const normalizeMessage = normalizer.normalize(message);
            return this.createEntry('thread-message', normalizeMessage.properties.id, normalizeMessage.properties);
          });
          thread.messages = _baseArray.default.create({
            content: contentMessages
          });
          return this.createEntry('thread', thread.id, thread);
        });
        nextPage = data === null || data === void 0 ? void 0 : (_data$pagination = data.pagination) === null || _data$pagination === void 0 ? void 0 : _data$pagination.nextPage;
      } catch {
        //
      }
      return _baseArray.default.create({
        content,
        nextPage
      });
    }
    _getManager(manager) {
      if (manager.userCode) {
        return {
          id: manager.userCode,
          name: manager.name,
          lastName: manager.lastName,
          mothersLastName: manager.mothersLastName
        };
      }
      return DEFAULT_MANAGER;
    }
    async createThread(subject, message, attachments) {
      const customer = await this.provider.pull('customer', 'getContextualData');
      const payload = {
        subject,
        customer: {
          id: this.currentSession.id,
          name: customer.name,
          lastName: customer.lastName,
          mothersLastName: customer.mothersLastName
        },
        isDraft: false,
        manager: this._getManager((0, _object.get)(customer, 'manager')),
        messages: [{
          content: message,
          sender: {
            type: {
              id: 'C',
              name: 'CLIENTE'
            }
          },
          attachments
        }]
      };
      return this.enax.POST('threads', payload);
    }
    deleteThread(threadId) {
      return this.enax.PUT('threads', {
        isArchived: true
      }, {
        threadId
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "enax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentSession", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ThreadsRepository;
});