define("@woody-lite/core-session/decorators/authenticated", ["exports", "@ember/debug", "@ember/routing/route", "@woody-lite/core-session/decorators/session", "@ember/service", "@ember/object"], function (_exports, _debug, _route, _session, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = authenticated;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  function authenticated(_ref) {
    let {
      app
    } = _ref;
    (false && !(typeof app === 'string') && (0, _debug.assert)("The 'authenticated' decorator needs an object with 'app' property", typeof app === 'string'));
    return function (target) {
      var _dec, _class, _descriptor, _descriptor2;
      (false && !(_route.default.detect(target)) && (0, _debug.assert)("The 'authenticated' decorator must be applied to a Route", _route.default.detect(target)));
      const klass = (_dec = (0, _session.default)(app), (_class = class _class extends target {
        constructor() {
          super(...arguments);
          _initializerDefineProperty(this, "currentSession", _descriptor, this);
          _initializerDefineProperty(this, "router", _descriptor2, this);
        }
        __checkSession(transition, currentSession) {
          if (!(0, _object.get)(currentSession || {}, 'tsec')) {
            var _this$router$_router$, _this$router$_router$2, _this$router$_router$3, _this$router$_router$4, _this$router$location, _this$router$location2, _this$router$location3;
            const transitionPath = (_this$router$_router$ = this.router._router.currentState) === null || _this$router$_router$ === void 0 ? void 0 : (_this$router$_router$2 = _this$router$_router$.router) === null || _this$router$_router$2 === void 0 ? void 0 : (_this$router$_router$3 = _this$router$_router$2.activeTransition) === null || _this$router$_router$3 === void 0 ? void 0 : (_this$router$_router$4 = _this$router$_router$3.intent) === null || _this$router$_router$4 === void 0 ? void 0 : _this$router$_router$4.url;
            const url = transitionPath || ((_this$router$location = this.router.location) === null || _this$router$location === void 0 ? void 0 : (_this$router$location2 = _this$router$location.location) === null || _this$router$location2 === void 0 ? void 0 : (_this$router$location3 = _this$router$location2.hash) === null || _this$router$location3 === void 0 ? void 0 : _this$router$location3.replace(/^#/, ''));
            transition.send(false, 'unauthenticated', url);
          }
        }
        async beforeModel(transition) {
          const {
            currentSession
          } = this;
          this.__checkSession(transition, currentSession);
          return super.beforeModel(...arguments);
        }
      }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentSession", [_dec], {
        configurable: true,
        enumerable: true,
        writable: true,
        initializer: null
      }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
        configurable: true,
        enumerable: true,
        writable: true,
        initializer: null
      })), _class));
      Object.defineProperty(klass, 'name', {
        value: target.name
      });
      return klass;
    };
  }
});