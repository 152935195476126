define("@woody-lite/core-session/components/modal-safe-tap-error", ["exports", "@woody-lite/core-foundation/components/modal", "@ember/object", "@woody-lite/core-session/constants", "@woody-lite/core-session/errors/user-choose-signature-method", "@woody-lite/core-session/errors/safetap-technical", "@woody-lite/core-session/templates/modal-safe-tap-error"], function (_exports, _modal, _object, _constants, _userChooseSignatureMethod, _safetapTechnical, _modalSafeTapError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const ERRORS = {
    invalidCard: {
      title: 'label.safeTap.errors.invalidCard.title',
      text1: 'label.safeTap.errors.invalidCard.text1',
      text2: 'label.safeTap.errors.invalidCard.text2'
    },
    technical: {
      title: 'label.safeTap.errors.technical.title',
      text1: 'label.safeTap.errors.technical.text1'
    },
    reading: {
      title: 'label.safeTap.errors.reading.title',
      text1: 'label.safeTap.errors.reading.text1',
      text2: 'label.safeTap.errors.reading.text2'
    }
  };
  let ModalSafeTapErrorComponent = (_class = class ModalSafeTapErrorComponent extends _modal.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "layout", _modalSafeTapError.default);
      _defineProperty(this, "errorType", _constants.SAFETAP_ERROR_TYPES.TECHNICAL_ERROR);
    }
    get showLink() {
      return this.errorType === _constants.SAFETAP_ERROR_TYPES.INVALID_CARD_ERROR;
    }
    get showAnotherMethod() {
      return this.errorType === _constants.SAFETAP_ERROR_TYPES.TECHNICAL_ERROR;
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      let {
        errorType
      } = this.model.options;
      errorType = ERRORS[errorType] ? errorType : _constants.SAFETAP_ERROR_TYPES.TECHNICAL_ERROR;
      this.errorType = errorType;
      this.title = ERRORS[this.errorType].title;
      this.text1 = ERRORS[this.errorType].text1;
      this.text2 = ERRORS[this.errorType].text2;
    }
    closeModal() {
      if (this.errorType === _constants.SAFETAP_ERROR_TYPES.TECHNICAL_ERROR) {
        return this.reject(new _safetapTechnical.SafeTapTechnicalError());
      }
      return this.reject();
    }
    openValidCards() {
      this.modal.open('card-list');
    }
    chooseAnotherMethod() {
      this.reject(new _userChooseSignatureMethod.UserChooseSignatureMethodError());
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "closeModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openValidCards", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openValidCards"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "chooseAnotherMethod", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "chooseAnotherMethod"), _class.prototype)), _class);
  _exports.default = ModalSafeTapErrorComponent;
});