define("@woody-lite/addon-crm-campaigns/models/event-action", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  class EventActionModel extends _object.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "trigger", 'ok');
      _defineProperty(this, "trace", true);
      _defineProperty(this, "dismissAfterAction", false);
      _defineProperty(this, "inAppScreenData", null);
    }
    get url() {
      return this._url;
    }
    set url(value) {
      if (value && value.includes('?')) {
        let [url] = value.split('?');
        if (url.indexOf('/') === 0) {
          url = url.replace('/', '');
        }
        if (url.indexOf('/') !== -1) {
          url = url.replace(/\//g, '.');
        }
        value = url;
      }
      this._url = value;
    }
  }
  _exports.default = EventActionModel;
});