define("@woody-lite/addon-feedback/components/modal-feedback", ["exports", "@ember/component", "@ember/template-factory", "@woody-lite/core-foundation/components/modal", "@woody-lite/core-foundation/utils/serialize-query-params", "@ember/object", "@ember/application", "@glimmer/tracking", "@woody-lite/core-foundation/utils/window-open", "@ember/service"], function (_exports, _component, _templateFactory, _modal, _serializeQueryParams, _object, _application, _tracking, _windowOpen, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <WrapperMain as |wrapper|>
  	<wrapper.header @type="modal-primary" as |header|>
  		<header.title @title={{t "label.feedbackHeader"}} />
  		<header.right as |i|>
  			<i.icon
  				data-id="btnClose"
  				@class="icon-close"
  				@onClick={{this.close}}
  				@accessibilityText={{t "label.close"}}
  			/>
  		</header.right>
  	</wrapper.header>
  
  	<wrapper.network />
  
  	<wrapper.content>
  		<article>
  			<IframeExternal
  				data-id="feedback-opinator"
  				@src={{this.url}}
  				@allowedDomains={{array "https://www4.opinator.com"}}
  				@class="vh"
  				@events={{hash
  					openModal=this.openModal
  					updateStar=this.updateStar
  					navigateToMarket=this.rateInMarket
  				}}
  			/>
  		</article>
  	</wrapper.content>
  </WrapperMain>
  */
  {
    "id": "wU36jvBW",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n\\t\"],[8,[30,1,[\"header\"]],null,[[\"@type\"],[\"modal-primary\"]],[[\"default\"],[[[[1,\"\\n\\t\\t\"],[8,[30,2,[\"title\"]],null,[[\"@title\"],[[28,[37,1],[\"label.feedbackHeader\"],null]]],null],[1,\"\\n\\t\\t\"],[8,[30,2,[\"right\"]],null,null,[[\"default\"],[[[[1,\"\\n\\t\\t\\t\"],[8,[30,3,[\"icon\"]],[[24,\"data-id\",\"btnClose\"]],[[\"@class\",\"@onClick\",\"@accessibilityText\"],[\"icon-close\",[30,0,[\"close\"]],[28,[37,1],[\"label.close\"],null]]],null],[1,\"\\n\\t\\t\"]],[3]]]]],[1,\"\\n\\t\"]],[2]]]]],[1,\"\\n\\n\\t\"],[8,[30,1,[\"network\"]],null,null,null],[1,\"\\n\\n\\t\"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n\\t\\t\"],[10,\"article\"],[12],[1,\"\\n\\t\\t\\t\"],[8,[39,2],[[24,\"data-id\",\"feedback-opinator\"]],[[\"@src\",\"@allowedDomains\",\"@class\",\"@events\"],[[30,0,[\"url\"]],[28,[37,3],[\"https://www4.opinator.com\"],null],\"vh\",[28,[37,4],null,[[\"openModal\",\"updateStar\",\"navigateToMarket\"],[[30,0,[\"openModal\"]],[30,0,[\"updateStar\"]],[30,0,[\"rateInMarket\"]]]]]]],null],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\\t\"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"wrapper\",\"header\",\"i\"],false,[\"wrapper-main\",\"t\",\"iframe-external\",\"array\",\"hash\"]]",
    "moduleName": "@woody-lite/addon-feedback/components/modal-feedback.hbs",
    "isStrictMode": false
  });
  let ModalFeedback = (_class = class ModalFeedback extends _modal.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "modal", _descriptor, this);
      _initializerDefineProperty(this, "provider", _descriptor2, this);
      _initializerDefineProperty(this, "slinky", _descriptor3, this);
      _initializerDefineProperty(this, "intl", _descriptor4, this);
      _initializerDefineProperty(this, "currentStars", _descriptor5, this);
    }
    get url() {
      const data = this.model.options;
      return this.getOpinatorParams(data);
    }
    getOpinatorParams(data) {
      let url = "https:".concat(data.feedbackUrl);
      const params = {
        ...data
      };
      delete params.feedbackUrl;
      delete params.isIOS;
      delete params.feedbackLang;
      url = url.replace(/(lang=[a-z]{2,})/g, "lang=".concat(data.feedbackLang));
      const unionChar = '&';
      const serialezQueryParams = (0, _serializeQueryParams.serializeQueryParams)(params);
      url = "".concat(url).concat(unionChar).concat(serialezQueryParams);
      return url;
    }
    get marketURL() {
      const data = this.model.options;
      const config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      return data.isIOS ? config.APP.markets.apple : config.APP.markets.google;
    }
    rateInMarket() {
      return (0, _windowOpen.windowOpen)(this.marketURL, this);
    }
    openModal(message) {
      this.modal.open('iframe', {
        src: message.data
      });
    }
    updateStar(message) {
      this.currentStars = message.data[0];
    }
    close() {
      this.send('reject', this.currentStars);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "slinky", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "currentStars", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "rateInMarket", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "rateInMarket"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateStar", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateStar"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype)), _class);
  _exports.default = ModalFeedback;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ModalFeedback);
});