define("@woody-lite/core-session/decorators/headers-bbva", ["exports", "@ember/service", "@ember/object", "@ember/application", "@woody-lite/core-session/utils/headers-bbva", "@ember/debug"], function (_exports, _service, _object, _application, _headersBbva, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = headersBBVA;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const ENAX = 'enax';
  const GAM = 'gam';
  const ALLOWED_TYPES = [ENAX, GAM];
  function headersBBVA(type) {
    (false && !(ALLOWED_TYPES.includes(type)) && (0, _debug.assert)('Only is allowed gam and enax types', ALLOWED_TYPES.includes(type)));
    return function (target) {
      var _class, _descriptor;
      const klass = (_class = class _class extends target {
        constructor() {
          super(...arguments);
          _initializerDefineProperty(this, "provider", _descriptor, this);
        }
        get device() {
          return this.provider.pull('device', 'getDevice');
        }
        get application() {
          return this._getApplication();
        }
        async _getApplication() {
          const device = await this.device;
          if (device.isNative) {
            return this.provider.pull('application', 'getNativeApplication');
          }
          return this.provider.pull('application', 'getApplication');
        }
        async before() {
          var _this$headers;
          const application = await this.application;
          const device = await this.device;
          const deviceId = (0, _object.get)(device, 'id');
          const environment = (0, _application.getOwner)(this).resolveRegistration('config:environment').APP.BBVA_ENV;
          const enaxHeaders = type === ENAX ? {
            'Accept-Language': (0, _object.get)(device, 'language'),
            ContactId: (_this$headers = this.headers) === null || _this$headers === void 0 ? void 0 : _this$headers.ContactId,
            'thirdparty-deviceid': deviceId
          } : {};
          this.headers = Object.assign({
            'Content-Language': (0, _object.get)(device, 'language'),
            'BBVA-User-Agent': (0, _headersBbva.getBBVAUserAgent)(application, device, environment)
          }, enaxHeaders, this.headers);
          return super.before(...arguments);
        }
      }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
        configurable: true,
        enumerable: true,
        writable: true,
        initializer: null
      })), _class);
      Object.defineProperty(klass, 'name', {
        value: target.name
      });
      return klass;
    };
  }
});