define("@woody-lite/core-traceability/services/rocky", ["exports", "@ember/service", "@ember/object", "@ember/application"], function (_exports, _service, _object, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const MNTUD = 'https://d19m7cewwe0ip8.cloudfront.net/{snippetId}/mntud.js';
  const FRAMED = 'https://d19m7cewwe0ip8.cloudfront.net/{snippetId}/framed.js';
  let RockyService = (_class = class RockyService extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "provider", _descriptor, this);
      this.config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
    }
    async isRockyActive() {
      const publicConfig = await this.provider.pull('configuration', 'getPublicConfiguration');
      return (0, _object.get)(publicConfig, 'rockyScript.isActive');
    }
    setDt(dt) {
      this.dt = dt;
    }
    async loadRocky(dt) {
      const dtValue = dt || this.dt;
      const rockyAvailable = await this.isRockyActive();
      const snippetId = (0, _object.get)(this.config, 'rocky.snippetId');
      if (!rockyAvailable) {
        return;
      }
      if (dtValue && window.loadScript) {
        await this.loadScriptOrSignal(MNTUD, dtValue, snippetId);
        if (dtValue === 'login') {
          await this.loadScriptOrSignal(FRAMED, dtValue, snippetId);
        }
      }
    }
    replaceSnippetId(scriptName, snippetId) {
      return scriptName.replace('{snippetId}', snippetId);
    }
    async loadScriptOrSignal(scriptName, dtValue, snippetId) {
      if (!window.trustLoaded) {
        window.trustLoaded = {};
      }
      const script = this.replaceSnippetId(scriptName, snippetId);
      if (window.trustLoaded[script]) {
        this.sendSignal(dtValue);
      } else {
        window.trustLoaded[script] = true;
        await window.loadScript(script, dtValue, snippetId);
      }
    }
    setStlgvalValue(step, values) {
      if (window.stlgval) {
        window.stlgval(step, values);
      }
    }
    sendSignal(signal) {
      if (window.valrun) {
        window.valrun(signal);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = RockyService;
});