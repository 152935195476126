define("@woody-lite/engine-documents-and-certificates/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _routes.default)(function () {
    this.route('documents');
    this.route('upload-documents');
    this.route('confirmation');
    this.route('certificates', function () {
      this.route('index');
      this.route('certificate-detail', {
        path: '/detail/:certificate_id'
      });
    });
    this.route('update-id-document', function () {
      this.route('index');
      this.route('id-document-info', {
        path: '/info'
      });
      this.route('capture-document');
      this.route('capture-selfie-info');
      this.route('capture-selfie');
      this.route('error-ocr');
      this.route('confirmation-validation');
      this.route('error-validation');
      this.route('sure-leave');
      this.route('update-document');
    });
  });
  _exports.default = _default;
});