define("@woody-lite/core-session/models/token-software", ["exports", "ember-object-data-model/models/base", "@ember/service", "ember-object-data-model/decorators/relationship", "ember-utils/utils/hash/guid", "@ember/object"], function (_exports, _base, _service, _relationship, _guid, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let TokenSoftwareModel = (_dec = (0, _relationship.default)(), _dec2 = (0, _relationship.default)(), (_class = class TokenSoftwareModel extends _base.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "nativeStorage", _descriptor, this);
      _initializerDefineProperty(this, "slinky", _descriptor2, this);
      _initializerDefineProperty(this, "enax", _descriptor3, this);
      _initializerDefineProperty(this, "provider", _descriptor4, this);
    }
    get device() {
      return this.provider.pull('device', 'getDevice');
    }
    get isIOS() {
      return (0, _object.get)(this.device, 'isIOS');
    }
    get customer() {
      return this.provider.pull('customer', 'getContextualData');
    }
    async _isPhoneValidated() {
      const customer = await this.customer;
      return customer.isPhoneValidated;
    }
    get isPhoneValidated() {
      return this._isPhoneValidated();
    }
    get privateConfiguration() {
      return this.provider.pull('configuration', 'getPrivateConfiguration');
    }
    get isEnabled() {
      return (0, _object.get)(this, 'supportsBiometrics');
    }
    async _isActivatable() {
      const isEnabled = this.isEnabled;
      const hasSecuredLockScreen = (0, _object.get)(this, 'hasSecuredLockScreen');
      const isPhoneValidated = await this.isPhoneValidated;
      const hasBiometricsRegistered = (0, _object.get)(this, 'hasBiometricsRegistered');
      return isEnabled && hasSecuredLockScreen && isPhoneValidated && hasBiometricsRegistered;
    }
    get isActivatable() {
      return this._isActivatable();
    }
    get isActive() {
      return this._isActive();
    }
    async _isActive() {
      if (!(await this.isActivatable)) {
        return false;
      }
      const deviceToken = await this.deviceToken;

      /* istanbul ignore if */
      if (!(deviceToken !== null && deviceToken !== void 0 && deviceToken.isActive)) {
        return false;
      }
      const remoteToken = await this.remoteToken;

      /* istanbul ignore if */
      if (!(remoteToken !== null && remoteToken !== void 0 && remoteToken.isActive)) {
        return false;
      }
      return true;
    }
    get deviceId() {
      return this._deviceId();
    }
    async _deviceId() {
      const response = await this.slinky.send('EasySign.getDeviceId');
      return response.deviceId;
    }
    get isSigningWithFaceId() {
      return this._isSigningWithFaceId();
    }
    async _isSigningWithFaceId() {
      /* istanbul ignore if */
      if (!(await this.isIOS)) {
        return false;
      }
      const capabilities = await this.device.getCapabilities();
      return (0, _object.get)(capabilities, 'supportsFaceId') && (0, _object.get)(capabilities, 'hasFaceIdRegistered');
    }
    async createRemoteToken() {
      const deviceId = await (0, _object.get)(this, 'deviceId');
      return this.enax.POST('software-tokens', {
        deviceId,
        deviceName: (0, _guid.default)()
      });
    }
    async createDeviceToken(_ref) {
      let {
        authCode,
        tokenId
      } = _ref;
      const deviceId = await (0, _object.get)(this, 'deviceId');
      return await this.slinky.send('EasySign.createToken', {
        authCode,
        tokenId,
        deviceId
      });
    }
    async activateRemoteToken(_ref2) {
      let {
        tokenId,
        activationData,
        seed,
        configuration
      } = _ref2;
      const deviceId = await this.deviceId;
      const {
        data
      } = await this.enax.POST('activate-software-token', {
        activationData,
        salt: seed,
        deviceId,
        authCodeHash: configuration
      }, {
        id: tokenId
      });
      return data;
    }
    async activateDeviceToken(_ref3) {
      let {
        tokenId,
        activationData,
        seed,
        configuration
      } = _ref3;
      await this.slinky.send('EasySign.activateToken', {
        activationData,
        seed,
        serial: tokenId,
        configuration
      });
      await this.nativeStorage.setItem('hasBiometricsSignActivated', true);
    }
    get deviceToken() {
      return this.provider.fetch('token-software', 'getDeviceToken', this);
    }
    get remoteToken() {
      return this.provider.fetch('token-software', 'getRemoteToken', this);
    }
    async generate(challenge) {
      const deviceToken = await (0, _object.get)(this, 'deviceToken');
      return deviceToken.generate(challenge);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "nativeStorage", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "slinky", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "enax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "deviceToken", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "deviceToken"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "remoteToken", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "remoteToken"), _class.prototype)), _class));
  _exports.default = TokenSoftwareModel;
});