define("@woody-lite/addon-crm-campaigns/models/enax-action", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TRIGGERS = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  const TRIGGERS = {
    OK: 'ok',
    CANCEL: 'cancel',
    LOAD: 'load',
    PROCESS_COMPLETED: 'processCompleted',
    PROCESS_ABANDONED: 'processAbandoned'
  };
  _exports.TRIGGERS = TRIGGERS;
  const ACTIONS = {
    [TRIGGERS.OK]: {
      eventType: 'INTERESTED',
      dismissAfterAction: false,
      trace: false
    },
    [TRIGGERS.CANCEL]: {
      eventType: 'REJECTED',
      dismissAfterAction: true,
      trace: true
    },
    [TRIGGERS.LOAD]: {
      eventType: 'DISPLAYED',
      dismissAfterAction: false,
      trace: false
    },
    [TRIGGERS.PROCESS_COMPLETED]: {
      eventType: 'ACQUIRED',
      dismissAfterAction: true,
      trace: false
    },
    [TRIGGERS.PROCESS_ABANDONED]: {
      eventType: 'ABANDONED',
      dismissAfterAction: false,
      trace: false
    }
  };
  class EnaxActionModel extends _object.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "trigger", null);
      _defineProperty(this, "campaignId", null);
      _defineProperty(this, "channel", null);
    }
    get dismissAfterAction() {
      var _ACTIONS$this$trigger;
      return (_ACTIONS$this$trigger = ACTIONS[this.trigger]) === null || _ACTIONS$this$trigger === void 0 ? void 0 : _ACTIONS$this$trigger.dismissAfterAction;
    }
    get trace() {
      var _ACTIONS$this$trigger2;
      return (_ACTIONS$this$trigger2 = ACTIONS[this.trigger]) === null || _ACTIONS$this$trigger2 === void 0 ? void 0 : _ACTIONS$this$trigger2.trace;
    }
    get eventType() {
      var _ACTIONS$this$trigger3;
      return (_ACTIONS$this$trigger3 = ACTIONS[this.trigger]) === null || _ACTIONS$this$trigger3 === void 0 ? void 0 : _ACTIONS$this$trigger3.eventType;
    }
    get inAppScreenData() {
      return {
        campaignId: this.campaignId,
        eventType: this.eventType
      };
    }
  }
  _exports.default = EnaxActionModel;
});