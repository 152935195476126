define("@woody-lite/addon-terms-and-conditions/models/terms-and-conditions/terms-and-conditions", ["exports", "ember-object-data-model/models/base", "@ember/service", "ember-object-data-model/decorators/relationship", "@ember/object"], function (_exports, _base, _service, _relationship, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let TermsAndConditionsModel = (_class = class TermsAndConditionsModel extends _base.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "provider", _descriptor, this);
      _defineProperty(this, "canBeSigned", true);
    }
    get document() {
      return this.provider.pull('terms-and-conditions', '_getDocument');
    }
    get staticDocument() {
      return this.provider.pull('terms-and-conditions', '_staticDocument');
    }
    async getCapabilities() {
      const capabilities = {
        visible: false,
        upgradeable: false,
        canBeSigned: this.canBeSigned
      };
      try {
        const getListFinancialService = await this.provider.pull('non-financial-services', 'getList');
        const listFinancialService = (0, _object.get)(getListFinancialService, 'data');
        if (listFinancialService) {
          const financialRelation = listFinancialService.find(item => item.nonFinancialProduct.id === (0, _object.get)(this, 'documentId'));
          if (financialRelation) {
            capabilities.visible = financialRelation.upgradeable;
            capabilities.upgradeable = financialRelation.upgradeable;
          } else {
            capabilities.visible = true;
          }
        }
      } catch {
        // Nope
      }
      return capabilities;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "document", [_relationship.default], Object.getOwnPropertyDescriptor(_class.prototype, "document"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "staticDocument", [_relationship.default], Object.getOwnPropertyDescriptor(_class.prototype, "staticDocument"), _class.prototype)), _class);
  _exports.default = TermsAndConditionsModel;
});