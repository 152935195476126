define("@woody-lite/addon-crm-campaigns/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ACTIONS = void 0;
  const ACTIONS = {
    ok: {
      eventType: 'INTERESTED'
    },
    cancel: {
      eventType: 'REJECTED'
    },
    load: {
      eventType: 'DISPLAYED'
    },
    complete: {
      eventType: 'ACQUIRED'
    },
    notNow: {
      eventType: 'ABANDONED'
    },
    scheduled: {
      eventType: 'SCHEDULED'
    }
  };
  _exports.ACTIONS = ACTIONS;
});