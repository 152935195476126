define("@woody-lite/core-session/components/modal-signature-choices", ["exports", "@woody-lite/core-foundation/components/modal", "@ember/object", "@glimmer/tracking", "@woody-lite/core-session/templates/modal-signature-choices", "@ember/utils", "@ember/service"], function (_exports, _modal, _object, _tracking, _modalSignatureChoices, _utils, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const CONFIG = {
    '05': {
      title: 'label.withAnSMS',
      duration: 1
    },
    150: {
      title: 'label.withYourContactlessCard',
      text: 'label.rememberToHaveOneOfYourContactlessCardsHandly',
      duration: 1,
      linkText: 'label.whichCardsCanUse',
      modal: 'card-list'
    },
    259: {
      title: 'label.withSmsAndSelfie',
      text: 'label.withSmsAndSelfieDescription',
      duration: 1
    }
  };
  let ModalSignatureChoicesComponent = (_class = class ModalSignatureChoicesComponent extends _modal.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "layout", _modalSignatureChoices.default);
      _initializerDefineProperty(this, "provider", _descriptor, this);
      _initializerDefineProperty(this, "veil", _descriptor2, this);
      _initializerDefineProperty(this, "title", _descriptor3, this);
      _initializerDefineProperty(this, "description", _descriptor4, this);
      _initializerDefineProperty(this, "selectedMethod", _descriptor5, this);
      _initializerDefineProperty(this, "methods", _descriptor6, this);
      this.veil.remove(true);
    }
    get disableButton() {
      return !this.selectedMethod;
    }
    async init() {
      super.init(...arguments);
      this.methods = await this.getMethods();
    }
    async getMethods() {
      const device = await this.provider.pull('device', 'getDevice');
      const methods = this.model.options.methods || [];
      const preferMethod = device.isIOS ? '05' : '150';
      const existMethod = !(0, _utils.isEmpty)(methods.find(method => method === preferMethod));
      return methods.map((method, index) => {
        const recommended = existMethod ? method === preferMethod : index === 0;
        return {
          id: method,
          ...CONFIG[method],
          recommended
        };
      });
    }
    closeModal() {
      this.reject();
    }
    submit() {
      this.veil.add();
      this.resolve(this.selectedMethod);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "veil", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "title", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'label.biometricSignature';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "description", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'label.toActivateTheBiometricSignature';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "selectedMethod", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "methods", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "submit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "submit"), _class.prototype)), _class);
  _exports.default = ModalSignatureChoicesComponent;
});