define("@woody-lite/core-session/decorators/-session-inactivity", ["exports", "@ember/debug", "@ember/object", "@ember/service", "@woody-lite/core-session/models/session/base"], function (_exports, _debug, _object, _service, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = sessionInactivity;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const TIMER_ID = 'inactivity';
  function sessionInactivity(timeout) {
    (false && !(typeof timeout === 'number') && (0, _debug.assert)("The 'sessionInactivity' decorator needs a number as max timeout", typeof timeout === 'number'));
    return function (target) {
      var _class, _descriptor;
      (false && !(_base.default.detect(target)) && (0, _debug.assert)("The 'authenticated' decorator must be applied to a Session model", _base.default.detect(target)));
      const klass = (_class = class _class extends target {
        constructor() {
          super(...arguments);
          _initializerDefineProperty(this, "provider", _descriptor, this);
        }
        _createTimers() {
          super._createTimers(...arguments);
          this._createTimer({
            id: TIMER_ID,
            callback: this._onInactivityTimeout,
            delay: timeout
          });
        }
        async _onUserInteraction() {
          await this.getTimer(TIMER_ID).run();
        }
        async _runTimers() {
          await super._runTimers(...arguments);
          const device = await this.provider.pull('device', 'getDevice');
          device.on('user-interaction', this, '_onUserInteraction');
        }
        async _clearTimers() {
          await super._clearTimers(...arguments);
          const device = await this.provider.pull('device', 'getDevice');
          device.off('user-interaction', this, '_onUserInteraction');
        }
        _onInactivityTimeout() {
          if (!(this.isDestroying || this.isDestroyed)) {
            this.logout();
          }
        }
      }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
        configurable: true,
        enumerable: true,
        writable: true,
        initializer: null
      }), _applyDecoratedDescriptor(_class.prototype, "_onInactivityTimeout", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_onInactivityTimeout"), _class.prototype)), _class);
      Object.defineProperty(klass, 'name', {
        value: target.name
      });
      return klass;
    };
  }
});