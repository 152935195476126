define("@woody-lite/addon-tasks/enaxs/announcements", ["exports", "@woody-lite/core-session/resources/client-enax", "@woody-mrs-potato/core-http/services/http"], function (_exports, _clientEnax, _http) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  class AnnouncementsEnax extends _clientEnax.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "cacheStrategy", _http.CACHE_STRATEGIES.NETWORK_FIRST);
      _defineProperty(this, "smc", 'SMC201400459');
      _defineProperty(this, "endpoint", '/announcements/V01/');
    }
    async done() {
      const response = await super.done(...arguments);
      return response === null || response === void 0 ? void 0 : response.data;
    }
  }
  _exports.default = AnnouncementsEnax;
});