define("@woody-lite/engine-accounts/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function serializeAccount(model) {
    return {
      account_id: model.id
    };
  }
  function serializeMovement(model) {
    return {
      movement_id: model.id
    };
  }
  var _default = (0, _routes.default)(function () {
    this.route('overview', function () {
      this.route('account', {
        path: '/:account_id',
        serialize: serializeAccount
      });
    });
    this.route('account', {
      path: '/:account_id',
      serialize: serializeAccount
    }, function () {
      this.route('more-info');
      this.route('finder');
      this.route('movement', {
        path: '/:movement_id',
        serialize: serializeMovement
      });
      this.route('retentions');
    });
  });
  _exports.default = _default;
});