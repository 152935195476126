define("@woody-lite/addon-utils/helpers/mask-value", ["exports", "@ember/component/helper", "@ember/utils"], function (_exports, _helper, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.maskValue = maskValue;
  function maskValue(value, _ref) {
    let {
      char = '*',
      length = 0
    } = _ref;
    if ((0, _utils.isEmpty)(value) && typeof value !== 'string') {
      return char.repeat(length);
    }
    return value;
  }
  var _default = (0, _helper.helper)((params, hash) => {
    const value = params[0];
    return maskValue(value, hash);
  });
  _exports.default = _default;
});