define("@woody-lite/core-session/models/configuration", ["exports", "ember-object-data-model/models/base", "ember-utils/utils/primitive/merge", "@ember/object"], function (_exports, _base, _merge, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function getLocations(obj) {
    const locations = {};

    // Loop over object keys
    Object.keys(obj).forEach(key => {
      const value = obj[key];
      if (value.isActive && value.relativeUrl && value.id) {
        locations[value.id] = value.relativeUrl;
      } else if (Array.isArray(value)) {
        (0, _merge.default)(locations, getLocations(value));
      }
    });
    return locations;
  }
  let ConfigurationModel = (_dec = (0, _object.computed)('content'), (_class = class ConfigurationModel extends _base.default {
    /**
     * An object that stores route names and its route path:
     * ```js
     * {
     *   'some-route': 'some.ember.route.path'
     * }
     * ```
     *
     * @type {Object}
     */
    get locations() {
      return getLocations(this.content);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "locations", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "locations"), _class.prototype)), _class));
  _exports.default = ConfigurationModel;
});