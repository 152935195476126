define("@woody-mrs-potato/addon-slinky/errors/slinky", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  class SlinkyError extends Error {
    constructor(error) {
      const message = "SlinkyError '".concat(error.event, "': ").concat(error.code);
      super(message);
      _defineProperty(this, "code", void 0);
      _defineProperty(this, "event", void 0);
      Object.setPrototypeOf(this, new.target.prototype);
      this.name = this.constructor.name;
      Object.assign(this, error);
    }
  }
  _exports.default = SlinkyError;
});