define("@woody-lite/addon-crm-campaigns/caass/experience-detail", ["exports", "@woody-lite/core-network/resources/caas", "@ember/application"], function (_exports, _caas, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  class ExperienceDetailCaas extends _caas.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "endpoint", '/content/caas/:country/app/experience/:country-app-experience.model.:id.json');
    }
    get country() {
      var _config$crmCampaigns;
      const config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      return (config === null || config === void 0 ? void 0 : (_config$crmCampaigns = config['crm-campaigns']) === null || _config$crmCampaigns === void 0 ? void 0 : _config$crmCampaigns.country) || 'spain';
    }
  }
  _exports.default = ExperienceDetailCaas;
});