define("@woody-lite/addon-crm-campaigns/caass/campaign-detail-content-fragment", ["exports", "@woody-lite/core-network/resources/caas", "@ember/application", "@ember/service"], function (_exports, _caas, _application, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const SPANISH_LANGUAGE = 'es';
  const ITALY_COUNTRY = 'italia';
  let CampaignDetailContentFragmentCaas = (_class = class CampaignDetailContentFragmentCaas extends _caas.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
    }
    get country() {
      var _config$crmCampaigns;
      const config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      return (config === null || config === void 0 ? void 0 : (_config$crmCampaigns = config['crm-campaigns']) === null || _config$crmCampaigns === void 0 ? void 0 : _config$crmCampaigns.country) || 'spain';
    }
    get endpoint() {
      if (this.country === ITALY_COUNTRY || this.suffix === SPANISH_LANGUAGE) {
        return '/content/caas/:country/app/crm/campaigns.model.:id.json';
      }
      return '/:suffix/content/caas/:country/app/crm/campaigns.model.:id.json';
    }
    get suffix() {
      return this.intl.primaryLocale;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = CampaignDetailContentFragmentCaas;
});