define("@woody-lite/core-session/resources/headers-gam", ["exports", "@woody-lite/core-network/resources/gam", "@woody-lite/core-session/decorators/headers-bbva"], function (_exports, _gam, _headersBbva) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  let HeadersGamResource = (_dec = (0, _headersBbva.default)('gam'), _dec(_class = class HeadersGamResource extends _gam.default {}) || _class);
  _exports.default = HeadersGamResource;
});