define("@woody-lite/addon-utils/helpers/validators/has-correlation", ["exports", "@ember/component/helper", "@ember/service", "@ember/object"], function (_exports, _helper, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.hasCorrelation = hasCorrelation;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  function hasCorrelation(key, maxCorr) {
    let result = false;
    let correlativesSum = 1;
    let correlativesSubtract = 1;
    let pos = 0;
    while (pos < key.length - 1 && result === false) {
      const currentPos = key.charAt(pos);
      const currentCharCode = currentPos.charCodeAt(0);
      const nextPos = key.charAt(pos + 1);
      const nextCharCode = nextPos.charCodeAt(0);
      if (currentCharCode === nextCharCode - 1) {
        correlativesSum++;
        correlativesSubtract = 1;
      } else if (currentCharCode === nextCharCode + 1) {
        correlativesSubtract++;
        correlativesSum = 1;
      } else {
        correlativesSum = 1;
        correlativesSubtract = 1;
      }
      pos++;
      if (correlativesSum > maxCorr || correlativesSubtract > maxCorr) {
        result = true;
      }
    }
    return result;
  }
  let HasCorrelationHelper = (_class = class HasCorrelationHelper extends _helper.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
    }
    compute(_ref, _ref2) {
      let [max] = _ref;
      let {
        message
      } = _ref2;
      return {
        message: message || this.intl.t('error.hasCorrelation', {
          max
        }),
        validate(component) {
          const value = ((0, _object.get)(component, '_value') || '').toLowerCase();
          return hasCorrelation(value, max);
        }
      };
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = HasCorrelationHelper;
});