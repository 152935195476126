define("@woody-lite/core-session/models/client", ["exports", "@woody-lite/core-session/models/session/private", "@ember/object", "@ember/service", "@glimmer/tracking", "@ember/utils"], function (_exports, _private, _object, _service, _tracking, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const AUTHENTICATION_TYPES = ['209'];
  let ClientModel = (_class = class ClientModel extends _private.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "enax", _descriptor, this);
      _initializerDefineProperty(this, "_isPreactivated", _descriptor2, this);
      _initializerDefineProperty(this, "_isPreoperative", _descriptor3, this);
      _initializerDefineProperty(this, "_isAnonymous", _descriptor4, this);
    }
    get isPreactivated() {
      return (0, _utils.isNone)(this._isPreactivated) ? (0, _object.get)(this, 'role') === 'A' : this._isPreactivated;
    }
    set isPreactivated(value) {
      this._isPreactivated = value;
    }
    get isPreoperative() {
      return (0, _utils.isNone)(this._isPreoperative) ? (0, _object.get)(this, 'role') === 'P' : this._isPreoperative;
    }
    set isPreoperative(value) {
      this._isPreoperative = value;
    }
    get isAnonymous() {
      return (0, _utils.isNone)(this._isAnonymous) ? (0, _object.get)(this, 'otherUserInfo.CLIENTE_ANONIMO') === 'S' : this._isAnonymous;
    }
    set isAnonymous(value) {
      this._isAnonymous = value;
    }
    get endDoc() {
      const matches = (0, _object.get)(this, 'id').match(/\d(?!.*\d)/);
      return matches === null || matches === void 0 ? void 0 : matches[0];
    }
    get akamaiEnding() {
      const matches = (0, _object.get)(this, 'id').match(/\d{2}(?!.*\d)/);
      return matches === null || matches === void 0 ? void 0 : matches[0];
    }
    get refreshAllowed() {
      return !AUTHENTICATION_TYPES.includes((0, _object.get)(this, 'authenticationType'));
    }
    async keepAlive() {
      super.keepAlive(...arguments);
      if ((0, _object.get)(this, 'tsec') && this.refreshAllowed) {
        await this.enax.POST('refresh-granting-ticket');
      } else {
        this._clearTimers();
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "enax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_isPreactivated", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_isPreoperative", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "_isAnonymous", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = ClientModel;
});