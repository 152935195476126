define("@woody-lite/core-session/repositories/customer", ["exports", "ember-object-data-model/repositories/repository", "@woody-lite/core-session/models/empty", "@woody-lite/core-session/decorators/session", "@ember/service", "@ember/object", "@ember/application"], function (_exports, _repository, _empty, _session, _service, _object, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const LAST_CLIENT_KEY = 'last-client';
  let CustomerRepository = (_dec = (0, _session.default)('client'), (_class = class CustomerRepository extends _repository.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "applicationStorage", _descriptor, this);
      _initializerDefineProperty(this, "enax", _descriptor2, this);
      _initializerDefineProperty(this, "provider", _descriptor3, this);
      _initializerDefineProperty(this, "currentSession", _descriptor4, this);
    }
    async getContextualData() {
      var _this$currentSession;
      const device = await this.provider.pull('device', 'getDevice');
      const id = (_this$currentSession = this.currentSession) === null || _this$currentSession === void 0 ? void 0 : _this$currentSession.id;
      if (!id) {
        return _empty.default.create();
      }
      if ((0, _object.get)(device, 'isNative')) {
        return this._getNativeContextualData(id);
      }
      return this._getCustomerLogins(id);
    }
    async getContextualDataHTML() {
      const id = (0, _object.get)(this.currentSession, 'id');
      return this._getCustomerLogins(id);
    }
    async _getNativeContextualData(customerId) {
      var _result;
      const response = await this.provider.pull('slinky', 'Session.getContextualDataResponse');
      let result = (0, _object.get)(response, 'result');
      if ((_result = result) !== null && _result !== void 0 && _result.data) {
        var _result2;
        const normalizer = (0, _application.getOwner)(this).lookup('normalizer:customerLogins');
        result = normalizer.normalize((_result2 = result) === null || _result2 === void 0 ? void 0 : _result2.data);
      }
      return this.createEntry('customer', customerId, {
        ...result,
        status: (0, _object.get)(response, 'status'),
        headers: (0, _object.get)(response, 'headers')
      });
    }
    async _getCustomerLogins(customerId) {
      const data = await this.enax.GET('customer-logins', {
        params: {
          customerId
        }
      });
      const normalizer = (0, _application.getOwner)(this).lookup('normalizer:customerLogins');
      const normalizedData = normalizer.normalize(data);
      return this.createEntry('customer', customerId, normalizedData);
    }
    async getLastClient() {
      const lastClient = await this.applicationStorage.getItem(LAST_CLIENT_KEY);
      if (!lastClient) {
        return _empty.default.create();
      }
      return this.createEntry('last-client', lastClient.id, lastClient);
    }
    async storeLastClient() {
      const customer = await this.provider.pull('customer', 'getContextualData');
      const {
        id,
        username
      } = (0, _object.getProperties)(customer.session, 'id', 'username');
      const lastClient = {
        id,
        name: (0, _object.get)(customer, 'customer.name'),
        surname2: (0, _object.get)(customer, 'customer.mothersLastName'),
        surname: (0, _object.get)(customer, 'customer.lastName'),
        username
      };
      await this.applicationStorage.setItem(LAST_CLIENT_KEY, lastClient);
      return this.provider.fetch('customer', 'getLastClient');
    }
    async deleteLastClient() {
      var _window$HtmlSessionId;
      const lastClient = await this.provider.fetch('customer', 'getLastClient');
      lastClient.unsync();
      await this.applicationStorage.removeItem(LAST_CLIENT_KEY);
      await ((_window$HtmlSessionId = window.HtmlSessionId) === null || _window$HtmlSessionId === void 0 ? void 0 : _window$HtmlSessionId.refresh());
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "applicationStorage", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "enax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentSession", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CustomerRepository;
});