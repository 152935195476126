define("@woody-lite/core-session/models/device-token-software", ["exports", "ember-object-data-model/models/base", "@woody-lite/core-session/constants", "@ember/debug", "@ember/service", "@ember/object"], function (_exports, _base, _constants, _debug, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let DeviceTokenSoftwareModel = (_class = class DeviceTokenSoftwareModel extends _base.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "slinky", _descriptor, this);
      _initializerDefineProperty(this, "provider", _descriptor2, this);
    }
    get isActive() {
      return _constants.STATUS.ACTIVE === (0, _object.get)(this, 'status.id');
    }
    async generate(challenge) {
      const serial = this.id;
      (false && !(serial) && (0, _debug.assert)('DeviceTokenSoftware model has no serial to generate a valid OTP', serial));
      const {
        otp
      } = await this.slinky.send('EasySign.createOtp', {
        serial,
        challenge
      });
      return otp;
    }
    async delete() {
      return this.provider.push('token-software', 'deleteDeviceToken', this);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "slinky", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = DeviceTokenSoftwareModel;
});