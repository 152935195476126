define("@woody-lite/core-session/enaxs/granting-tickets", ["exports", "@woody-lite/core-network/resources/enax", "@woody-mrs-potato/core-http/services/http", "@woody-lite/core-session/decorators/headers-bbva"], function (_exports, _enax, _http, _headersBbva) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  let GrantingTicketsEnax = (_dec = (0, _headersBbva.default)('enax'), _dec(_class = class GrantingTicketsEnax extends _enax.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "endpoint", '/TechArchitecture/grantingTickets/V02');
      _defineProperty(this, "smc", 'SMC201400151');
      _defineProperty(this, "cacheStrategy", _http.CACHE_STRATEGIES.NETWORK_ONLY);
    }
    get credentials() {
      if (this.method === 'POST') {
        return 'include';
      }
      return 'same-origin';
    }
  }) || _class);
  _exports.default = GrantingTicketsEnax;
});