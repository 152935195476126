define("@woody-lite/addon-crm-campaigns/components/bubbles-carousel", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @bubbles}}
  	<div
  		data-id="customProducts"
  		class="panel-container"
  		data-theme="secondary"
  	>
  		<Compositions::CompositionCarouselSwipe
  			@content={{@bubbles}}
  			@accessibilityText={{t "label.personalizedProducts"}}
  			@fullWidth={{true}}
  			@controls={{component
  				"cards/card-control"
  				accessibilityTextPrevious=(t "label.accesibility.previous")
  				accessibilityTextNext=(t "label.accesibility.next")
  			}}
  			as |value slide|
  		>
  			<slide.card @type="product" @boxLook={{false}}>
  				<NotificationCard
  					@model={{value}}
  					data-id={{concat "feedbackBubble" value.id}}
  					@visible={{slide.isSelected}}
  					@currentSession={{@currentSession}}
  					@transitionTo={{@transitionTo}}
  					@openModal={{@openModal}}
  				/>
  			</slide.card>
  		</Compositions::CompositionCarouselSwipe>
  	</div>
  {{/if}}
  */
  {
    "id": "hIedzxUK",
    "block": "[[[41,[30,1],[[[1,\"\\t\"],[10,0],[14,\"data-id\",\"customProducts\"],[14,0,\"panel-container\"],[14,\"data-theme\",\"secondary\"],[12],[1,\"\\n\\t\\t\"],[8,[39,1],null,[[\"@content\",\"@accessibilityText\",\"@fullWidth\",\"@controls\"],[[30,1],[28,[37,2],[\"label.personalizedProducts\"],null],true,[50,\"cards/card-control\",0,null,[[\"accessibilityTextPrevious\",\"accessibilityTextNext\"],[[28,[37,2],[\"label.accesibility.previous\"],null],[28,[37,2],[\"label.accesibility.next\"],null]]]]]],[[\"default\"],[[[[1,\"\\n\\t\\t\\t\"],[8,[30,3,[\"card\"]],null,[[\"@type\",\"@boxLook\"],[\"product\",false]],[[\"default\"],[[[[1,\"\\n\\t\\t\\t\\t\"],[8,[39,4],[[16,\"data-id\",[28,[37,5],[\"feedbackBubble\",[30,2,[\"id\"]]],null]]],[[\"@model\",\"@visible\",\"@currentSession\",\"@transitionTo\",\"@openModal\"],[[30,2],[30,3,[\"isSelected\"]],[30,4],[30,5],[30,6]]],null],[1,\"\\n\\t\\t\\t\"]],[]]]]],[1,\"\\n\\t\\t\"]],[2,3]]]]],[1,\"\\n\\t\"],[13],[1,\"\\n\"]],[]],null]],[\"@bubbles\",\"value\",\"slide\",\"@currentSession\",\"@transitionTo\",\"@openModal\"],false,[\"if\",\"compositions/composition-carousel-swipe\",\"t\",\"component\",\"notification-card\",\"concat\"]]",
    "moduleName": "@woody-lite/addon-crm-campaigns/components/bubbles-carousel.hbs",
    "isStrictMode": false
  });
  const BubblesCarouselComponent = (0, _templateOnly.default)();
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BubblesCarouselComponent);
  _exports.default = _default;
});