define("@woody-lite/addon-crm-campaigns/components/carousel-futura-bubbles", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _component2, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Compositions::CompositionCarouselSwipe
  	class={{if @transparent "bg_transparent"}}
  	@content={{@bubbles}}
  	@accessibilityText={{t "label.personalizedProducts"}}
  	@controls={{component
  		"cards/card-control"
  		accessibilityTextPrevious=(t "label.accesibility.previous")
  		accessibilityTextNext=(t "label.accesibility.next")
  	}}
  	@selected={{if (gte this.selected @bubbles.length) 0 this.selected}}
  	@onChange={{fn (mut this.selected)}}
  	as |bubble slide|
  >
  	<CarouselFuturaBubblesCard
  		@bubble={{bubble}}
  		@slide={{slide}}
  		@currentSession={{@currentSession}}
  		@transitionTo={{@transitionTo}}
  		@openModal={{@openModal}}
  		@onClose={{@onClose}}
  	/>
  </Compositions::CompositionCarouselSwipe>
  */
  {
    "id": "Yhzp1f2z",
    "block": "[[[8,[39,0],[[16,0,[52,[30,1],\"bg_transparent\"]]],[[\"@content\",\"@accessibilityText\",\"@controls\",\"@selected\",\"@onChange\"],[[30,2],[28,[37,2],[\"label.personalizedProducts\"],null],[50,\"cards/card-control\",0,null,[[\"accessibilityTextPrevious\",\"accessibilityTextNext\"],[[28,[37,2],[\"label.accesibility.previous\"],null],[28,[37,2],[\"label.accesibility.next\"],null]]]],[52,[28,[37,4],[[30,0,[\"selected\"]],[30,2,[\"length\"]]],null],0,[30,0,[\"selected\"]]],[28,[37,5],[[28,[37,6],[[30,0,[\"selected\"]]],null]],null]]],[[\"default\"],[[[[1,\"\\n\\t\"],[8,[39,7],null,[[\"@bubble\",\"@slide\",\"@currentSession\",\"@transitionTo\",\"@openModal\",\"@onClose\"],[[30,3],[30,4],[30,5],[30,6],[30,7],[30,8]]],null],[1,\"\\n\"]],[3,4]]]]]],[\"@transparent\",\"@bubbles\",\"bubble\",\"slide\",\"@currentSession\",\"@transitionTo\",\"@openModal\",\"@onClose\"],false,[\"compositions/composition-carousel-swipe\",\"if\",\"t\",\"component\",\"gte\",\"fn\",\"mut\",\"carousel-futura-bubbles-card\"]]",
    "moduleName": "@woody-lite/addon-crm-campaigns/components/carousel-futura-bubbles.hbs",
    "isStrictMode": false
  });
  let CarouselFuturaBubblesComponent = (_class = class CarouselFuturaBubblesComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "select", _descriptor, this);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "select", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  })), _class);
  _exports.default = CarouselFuturaBubblesComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CarouselFuturaBubblesComponent);
});