define("@woody-mrs-potato/core-storages/storages/cache", ["exports", "@woody-mrs-potato/core-storages/storages/local"], function (_exports, _local) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  class CacheStorage extends _local.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "namespace", 'cache');
    }
  }
  _exports.default = CacheStorage;
});