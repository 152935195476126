define("@woody-lite/engine-sign-up-it/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _routes.default)(function () {
    this.route('identity-validation', function () {
      this.route('id-document-info', {
        path: '/info'
      });
      this.route('id-document-type', {
        path: '/select-id'
      });
      this.route('capture-document');
      this.route('error-validation');
      this.route('incomplete-signup');
      this.route('confirmation-validation');
      this.route('ocr-warning');
      this.route('error-ocr');
      this.route('validation-help-options');
      this.route('validation-help');
      this.route('sure-leave');
      this.route('closed');
      this.route('error-browser');
    });
    this.route('configure-product', function () {
      this.route('send-card');
      this.route('confirmation-card');
      this.route('error-process');
      this.route('aditional-documentation');
      this.route('warning-proccess');
    });
    this.route('confirmation');
    this.route('user-data');
  });
  _exports.default = _default;
});