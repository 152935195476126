define("@woody-mrs-potato/core-storages/storages/local", ["exports", "@woody-mrs-potato/core-storages/storages/base", "@ember/object", "@ember/utils", "@ember/runloop", "@ember/array"], function (_exports, _base, _object, _utils, _runloop, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  class LocalStorage extends _base.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "adapter", window.localStorage);
      _defineProperty(this, "namespace", 'local');
    }
    init() {
      super.init(...arguments);
      this.initialContent = this._initialContent();
      (0, _object.set)(this, 'content', this.initialContent);
    }
    _initialContent() {
      const content = _object.default.create();
      const adapter = this.adapter;
      if (!adapter) {
        return content;
      }

      // Loop localStorage keys.
      for (let i = 0; i < adapter.length; i++) {
        const key = adapter.key(i);
        const keyData = this.deserializeKey(key);

        // Set data if key namespace matches with the storage one.
        if (this.namespace === keyData.namespace) {
          const value = this.deserialize(adapter.getItem(key));

          // Sync memory.
          if ((0, _utils.isPresent)(value)) {
            (0, _object.set)(content, key, value);
          }
        }
      }
      return content;
    }
    clear() {
      this.keys().forEach(key => {
        key = this.serializeKey(key);
        this._delete(key);
      });
    }
    keys() {
      const adapter = this.adapter;
      const keys = super.keys(...arguments);
      if (adapter) {
        for (let i = 0; i < adapter.length; i++) {
          const key = adapter.key(i);
          const keyData = this.deserializeKey(key);

          // Set data if key namespace matches with the storage one.
          if (this.namespace === keyData.namespace) {
            keys.addObject(keyData.key);
          }
        }
      }
      return keys;
    }
    _save(key) {
      // Save to memory.
      super._save(...arguments);
      if (!this.adapter) {
        return;
      }

      // Defer localStorage call.
      (0, _runloop.schedule)('actions', this, () => {
        const keyParts = key.split('.');
        const isNestedProperty = keyParts.length > 1;
        if (isNestedProperty) {
          // Update root value
          key = keyParts[0];
        }

        // Retrieve value from memory storage.
        const value = (0, _object.get)(this, "content.".concat(key));
        if (value) {
          const serializedValue = this.serialize(value);
          if (serializedValue) {
            this._saveToAdapter(key, serializedValue);
          }
        }
      });
    }
    _saveToAdapter(key, serializedValue) {
      try {
        this.adapter.setItem(key, serializedValue);
      } catch {
        // Sometimes we can't set data to the localStorage, for example
        // in some browsers incognito mode.
        return false;
      }
    }
    _delete(key) {
      // Delete from memory.
      super._delete(...arguments);
      if (this.adapter) {
        // Defer localStorage deletion.
        (0, _runloop.schedule)('actions', this, '_deleteFromAdapter', key);
      }
    }
    _deleteFromAdapter(key) {
      try {
        this.adapter.removeItem(key);
      } catch {
        // Sometimes we can't remove data to the localStorage, for example
        // in some browsers incognito mode.
        return false;
      }
    }
    serialize(value) {
      if ((0, _utils.typeOf)(value) === 'object' || Array.isArray(value)) {
        try {
          value = JSON.stringify(value);
        } catch {
          // Sometimes we can't stringify due to circular refs
          // in complex objects, so we won't bother storing then.
          return false;
        }
      }
      return value.toString();
    }
    deserialize(value) {
      try {
        value = JSON.parse(value);
        // Wrap array.
        if (Array.isArray(value)) {
          value = (0, _array.A)(value);
        }
      } catch {
        // Value is not a jsonable object.
      }
      return value;
    }
    handleStorageEvent(event) {
      const adapter = this.adapter;
      if (!adapter || !event.key) {
        return;
      }
      if (adapter === event.storageArea) {
        // eslint-disable-next-line ember/classic-decorator-no-classic-methods
        this.notifyPropertyChange(this.deserializeKey(event.key));
      }
    }
  }
  _exports.default = LocalStorage;
});