define("@woody-lite/core-session/components/modal-access-password", ["exports", "@woody-lite/core-foundation/components/modal", "@ember/service", "@ember/object", "ember-concurrency-decorators", "@woody-lite/core-session/templates/modal-access-password", "@glimmer/tracking", "ember"], function (_exports, _modal, _service, _object, _emberConcurrencyDecorators, _modalAccessPassword, _tracking, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let DEFAULT_ALERT = 4000;

  // eslint-disable-next-line ember/no-ember-testing-in-module-scope
  if (_ember.default.testing) {
    DEFAULT_ALERT = 1;
  }
  const DEFAULT_IMAGE = 'banners/clave_acceso.svg';
  const ERROR_IMAGE = 'banners/clave_acceso_error.svg';
  let ModalAccessPassword = (_dec = (0, _emberConcurrencyDecorators.task)({
    drop: true
  }), (_class = class ModalAccessPassword extends _modal.default {
    constructor() {
      var _this$veil;
      super(...arguments);

      // TODO: Remove into DB-230740
      _initializerDefineProperty(this, "alerter", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "veil", _descriptor3, this);
      _initializerDefineProperty(this, "image", _descriptor4, this);
      _initializerDefineProperty(this, "key", _descriptor5, this);
      _defineProperty(this, "errorImage", ERROR_IMAGE);
      _defineProperty(this, "layout", _modalAccessPassword.default);
      if ((_this$veil = this.veil) !== null && _this$veil !== void 0 && _this$veil.show) {
        this.veil.remove();
      }
    }
    didOpen() {
      super.didOpen(...arguments);
      this.element.querySelector('[data-id="txtSignatureAccess"] input').focus();
    }
    *submit() {
      const options = (0, _object.get)(this, 'model.options');
      const key = (0, _object.get)(this, 'key');
      const resource = options.resource;

      // Get promise.
      if (options.factory === 'ENAX') {
        (0, _object.set)(resource, 'headers.authenticationdata', "".concat(options.data, "=").concat(key));
      }
      try {
        this.veil.add();
        const data = yield resource.retry();
        return this.close(data);
      } catch (e) {
        if (e !== null && e !== void 0 && e.closeLevelChange) {
          return this.reject(e);
        }
        const error = (e === null || e === void 0 ? void 0 : e.alert) || 'Error';
        this.image = this.errorImage;
        return this.alerter.add({
          text: error,
          type: 'float',
          visualType: 'error',
          accesibilityText: error,
          duration: DEFAULT_ALERT
        });
      } finally {
        this.key = '';
        this.veil.remove();
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "alerter", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "veil", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "image", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return DEFAULT_IMAGE;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "key", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "submit", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "submit"), _class.prototype)), _class));
  _exports.default = ModalAccessPassword;
});