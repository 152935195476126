define("@woody-mrs-potato/addon-slinky/wires/android", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function isPredicate(predicate) {
    return (predicate === null || predicate === void 0 ? void 0 : predicate.postMessage) !== undefined;
  }
  class AndroidWire {
    constructor() {
      _defineProperty(this, "isEnabled", Boolean(window.webViewInterface));
    }
    send(event, message) {
      if (!this.isEnabled) {
        throw new Error('Android wire interface does not exist');
      }
      const predicate = window[event];
      if (!predicate || !isPredicate(predicate)) {
        throw new Error("Event ".concat(event, " does not exist on Android wire interface implementation"));
      }
      predicate.postMessage(JSON.stringify(message));
    }
  }
  _exports.default = AndroidWire;
});