define("@woody-lite/addon-crm-campaigns/repositories/crm-campaigns", ["exports", "@ember/service", "@ember/object", "@ember/utils", "@ember/array", "ember-object-data-model/repositories/repository", "ember-object-data-model/models/base-array", "@ember/application", "ember-object-data-model/decorators/depends-on"], function (_exports, _service, _object, _utils, _array, _repository, _baseArray, _application, _dependsOn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const MIN_FEEDBACK_SCORE = 3;
  const LIMITS = {
    INDEPENDENT: '0000011850',
    NON_INDEPENDENT: '0000007294'
  };
  function filterVisibleCampaigns(campaign, hasLimits) {
    /* istanbul ignore if */
    if ((0, _object.get)(campaign, 'isOcl') || (0, _object.get)(campaign, 'isPayrollAdvance')) {
      return hasLimits;
    }
    return !(0, _object.get)(campaign, 'isExperience');
  }
  let CrmCampaignsRepository = (_dec = (0, _dependsOn.default)('intl.primaryLocale'), _dec2 = (0, _dependsOn.default)('intl.primaryLocale'), _dec3 = (0, _dependsOn.default)('intl.primaryLocale'), (_class = class CrmCampaignsRepository extends _repository.default {
    constructor() {
      var _config$crmCampaigns;
      super(...arguments);
      _initializerDefineProperty(this, "caas", _descriptor, this);
      _initializerDefineProperty(this, "enax", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "provider", _descriptor4, this);
      const config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      this.limits = (config === null || config === void 0 ? void 0 : (_config$crmCampaigns = config['crm-campaigns']) === null || _config$crmCampaigns === void 0 ? void 0 : _config$crmCampaigns.limits) || LIMITS;
    }
    async getChannel() {
      const device = await this.provider.pull('device', 'getDevice');
      const deviceSO = device.isAndroid ? 'Android' : 'iOS';
      const classification = device.isNative ? deviceSO : 'Mobile';
      return {
        classification
      };
    }
    async getBubbles(session) {
      let bubbles = [];
      try {
        const customer = await this.provider.pull('customer', 'getContextualData');
        bubbles = await this.provider.pull('crm-campaigns', 'getCrmCampaigns', session, customer);
      } catch {
        //
      }
      return _baseArray.default.create({
        content: bubbles
      });
    }

    /**
     * Retrieves all the active offers for a customer.
     */
    async getCrmOffers(session) {
      const device = await this.provider.pull('device', 'getDevice');
      let offers;
      if ((0, _object.get)(device, 'isNative')) {
        const response = await this.provider.pull('slinky', 'Configuration.getCampaignsResponse');
        offers = (0, _object.get)(response, 'result.data') || [];
      } else {
        const channel = await this.getChannel();
        const {
          data: {
            data
          }
        } = await this.enax.POST('crm-offers', {
          channel,
          customerId: (0, _object.get)(session, 'id')
        }, {
          currentSession: session
        });
        offers = data || [];
      }
      const content = offers.map(offer => this._modelizeCampaign(offer, session));
      return _baseArray.default.create({
        content
      });
    }

    /**
     * Retrieves all the active offers associated to a campaign for a customer.
     */
    async getCrmCampaigns(session, customer) {
      await customer;
      const [campaigns, limits] = await Promise.all([this.provider.pull('crm-campaigns', 'getCrmOffers', session), this._getLimits(session, customer)]);
      const visibleCampaigns = campaigns.filter(campaign => filterVisibleCampaigns(campaign, limits));
      await Promise.allSettled(visibleCampaigns.map(campaign => this._assignCampaignDetail(campaign, limits)));
      const localizedCampaigns = visibleCampaigns.filter(campaign => (0, _object.get)(campaign, 'location'));
      try {
        await this._assignFeedbackDetail(session, localizedCampaigns);
      } catch {
        //
      }
      return _baseArray.default.create({
        content: localizedCampaigns.sort((campaA, campaB) => (0, _object.get)(campaB, 'sourcePriority') - (0, _object.get)(campaA, 'sourcePriority'))
      });
    }
    async getCrmExperiences(session, customer) {
      await customer;
      const campaigns = await this.provider.pull('crm-campaigns', 'getCrmOffers', session);
      const experiences = campaigns.filterBy('isExperienceCampaign');
      await Promise.allSettled(experiences.map(campaign => this._assignExperienceDetail(campaign)));
      const visibleExperiences = experiences.filter(experience => {
        var _Object$keys;
        return ((_Object$keys = Object.keys((0, _object.get)(experience, 'detailExperience'))) === null || _Object$keys === void 0 ? void 0 : _Object$keys.length) !== 0;
      });
      return _baseArray.default.create({
        content: visibleExperiences.sort((campaA, campaB) => (0, _object.get)(campaB, 'sourcePriority') - (0, _object.get)(campaA, 'sourcePriority'))
      });
    }
    _modelizeCampaign(campaign, session) {
      const sourcePriority = campaign.priority;
      const mainDescription = campaign.description;
      delete campaign.priority;
      delete campaign.description;
      const offerData = Object.assign({
        customerId: (0, _object.get)(session, 'id'),
        sourcePriority,
        mainDescription
      }, campaign);
      return this.createEntry('crm-campaign', campaign.id, offerData);
    }
    async _assignCampaignDetail(campaign, limits) {
      const id = (0, _object.get)(campaign, 'campaign.id');
      const privateConfig = await this.provider.pull('configuration', 'getPrivateConfiguration');
      let data = {};

      // content fragment details
      if ((0, _object.get)(privateConfig, 'cfBubbles.isActive')) {
        const response = await this.caas.GET('campaign-detail-content-fragment', {
          id
        });
        const normalizer = (0, _application.getOwner)(this).lookup('normalizer:crm-campaign-content-fragment');
        data = normalizer.normalize(response === null || response === void 0 ? void 0 : response.data);
      } else {
        const response = await this.caas.GET('campaign-detail', {
          id
        });
        data = response.data;
      }
      if (limits) {
        data.limits = limits;
      }
      data.channel = await this.getChannel();
      (0, _object.setProperties)(campaign, data);
      campaign.sync();
    }
    async _assignExperienceDetail(experience) {
      var _get, _data$Items, _data$Items$root, _data$Items$root$Ite, _data$Items$root$Ite$;
      const id = (_get = (0, _object.get)(experience, 'campaign.id')) === null || _get === void 0 ? void 0 : _get.toLowerCase();
      const {
        data
      } = await this.caas.GET('experience-detail', {
        id
      });
      /* istanbul ignore next */
      const elements = (data === null || data === void 0 ? void 0 : (_data$Items = data[':items']) === null || _data$Items === void 0 ? void 0 : (_data$Items$root = _data$Items.root) === null || _data$Items$root === void 0 ? void 0 : (_data$Items$root$Ite = _data$Items$root[':items']) === null || _data$Items$root$Ite === void 0 ? void 0 : (_data$Items$root$Ite$ = _data$Items$root$Ite.dynamiccontentfragme) === null || _data$Items$root$Ite$ === void 0 ? void 0 : _data$Items$root$Ite$.elements) || [];
      const elementsMapped = Object.keys(elements).map(key => {
        return {
          key,
          value: elements[key].value
        };
      });
      const cleanElements = elementsMapped.reduce((result, currentElement) => {
        result[currentElement.key] = currentElement.value;
        return result;
      }, {});
      const {
        caas
      } = (0, _application.getOwner)(this).resolveRegistration('config:environment').network.hosts;
      if (cleanElements.imageURL) {
        cleanElements.imageURL = "".concat(caas).concat(cleanElements.imageURL);
      }
      (0, _object.setProperties)(experience, {
        detailExperience: cleanElements
      });
      experience.sync();
    }
    async _assignFeedbackDetail(session, campaigns) {
      campaigns = campaigns.filter(campaign => (0, _object.get)(campaign, 'opinatorId'));
      const ids = (0, _array.A)(campaigns).uniqBy('opinatorId').map(campaign => (0, _object.get)(campaign, 'opinatorId'));
      if ((0, _utils.isPresent)(ids)) {
        let aggregatedFeedback = await this.provider.push('feedback', 'getCrmCampaignsFeedback', session, ids);
        aggregatedFeedback = aggregatedFeedback.filter(feedback => (0, _object.get)(feedback, 'averageScore') >= MIN_FEEDBACK_SCORE);
        campaigns.forEach(campaign => {
          const feedback = aggregatedFeedback.find(feed => (0, _object.get)(feed, 'id') === (0, _object.get)(campaign, 'opinatorId'));
          if (feedback) {
            (0, _object.set)(campaign, 'feedback', feedback);
            campaign.sync();
          }
        });
      }
    }
    async _getLimits(session, customer) {
      if (!(0, _object.get)(customer, 'hasPreGrantedLoan') || !(0, _object.get)(customer, 'isPhoneValidated')) {
        return null;
      }
      const productId = (0, _object.get)(customer, 'isFreelance') ? this.limits.INDEPENDENT : this.limits.NON_INDEPENDENT;
      let informationLimits = [];
      try {
        var _response$data;
        const response = await this.enax.POST('list-customer-products-limits', {
          productId
        }, {
          customerId: (0, _object.get)(session, 'id'),
          currentSession: session
        });

        /* istanbul ignore next */
        informationLimits = (response === null || response === void 0 ? void 0 : (_response$data = response.data) === null || _response$data === void 0 ? void 0 : _response$data.informationLimits) || [];
      } catch {
        //
      }
      const LIMITS_RISK_TYPE = 'C';
      /* istanbul ignore next */
      const type = (0, _array.A)(informationLimits).findBy('riskType.id', LIMITS_RISK_TYPE) || {};
      const maxAmount = (0, _object.get)(type, 'amountLimit.amount');
      const periodMaximum = type === null || type === void 0 ? void 0 : type.periodMaximun;
      if (maxAmount && periodMaximum) {
        return {
          maxAmount,
          productTypeCode: productId,
          maxTerm: periodMaximum.amount,
          limits: []
        };
      }
      return null;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "caas", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "enax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getCrmOffers", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "getCrmOffers"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getCrmCampaigns", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "getCrmCampaigns"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getCrmExperiences", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "getCrmExperiences"), _class.prototype)), _class));
  _exports.default = CrmCampaignsRepository;
});